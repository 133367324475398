import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: object | number | string | undefined
  h?: object | number | string | undefined
}


export const DestinyText: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 239 36" { ...props }>
      <path
        fill="#082471"
        d="M6.20001 0.399902H0.5V16.1999H6.20001C10.9 16.1999 14.2 12.8999 14.2 8.29993C14.2 3.69993 10.8 0.399902 6.20001 0.399902ZM3.60001 3.3999H6.10001C9.00001 3.3999 11 5.3999 11 8.3999C11 11.2999 9.00001 13.3999 6.10001 13.3999H3.60001V3.3999Z"
      />
      <path
        fill="#082471"
        d="M22.9 9.59991H28.7V6.69992H22.9V3.3999H29.3V0.399902H19.8V16.2999H29.5V13.2999H22.9V9.59991Z"
      />
      <path
        fill="#082471"
        d="M40.7 6.7002C38.7 6.2002 37.6 5.9002 37.6 4.8002C37.6 3.7002 38.8 3.2002 40.1 3.2002C41.3 3.2002 42.6 3.7002 42.6 5.2002V5.50018H45.7V5.2002C45.7 2.1002 43.6 0.200195 40.1 0.200195C36.6 0.200195 34.5 2.00018 34.5 4.90018C34.5 8.30018 37.4 9.00018 39.7 9.50018C41.7 10.0002 42.9 10.3002 42.9 11.5002C42.9 12.8002 41.5 13.5002 40.2 13.5002C38.4 13.5002 37.3 12.6002 37.3 11.2002V10.9002H34.2V11.2002C34.2 14.4002 36.6 16.5002 40.2 16.5002C43.8 16.5002 46 14.6002 46 11.5002C46.1 8.00018 43.1 7.3002 40.7 6.7002Z"
      />
      <path
        fill="#082471"
        d="M49.9 3.3999H54.4V16.2999H57.5V3.3999H61.9V0.399902H49.9V3.3999Z"
      />
      <path
        fill="#082471"
        d="M70.1 0.399902H67V16.1999H70.1V0.399902Z"
      />
      <path
        fill="#082471"
        d="M86.7 10.4999L79.4 0.399902H76.7V16.2999H79.7V5.99991L87.2 16.2999H89.7V0.399902H86.7V10.4999Z"
      />
      <path
        fill="#082471"
        d="M101.9 6.99991L98 0.399902H94.6L100.3 9.8999V16.2999H103.5V9.8999L109.2 0.399902H105.8L101.9 6.99991Z"
      />
      <path
        fill="#082471"
        d="M130.7 5.49991C130.7 2.49991 128.4 0.399902 125.3 0.399902H119.1V16.1999H122.2V10.5999H124.6L127.9 16.1999H131.4L127.8 10.0999C129.6 9.29991 130.7 7.59991 130.7 5.49991ZM127.5 5.49991C127.5 6.79991 126.5 7.69992 125.1 7.69992H122.1V3.3999H125.1C126.6 3.2999 127.5 4.19991 127.5 5.49991Z"
      />
      <path
        fill="#082471"
        d="M143.8 0.100098C139 0.100098 135.5 3.60011 135.5 8.30011C135.5 13.0001 139.1 16.5001 143.8 16.5001C148.5 16.5001 152.1 13.0001 152.1 8.30011C152.1 3.60011 148.5 0.100098 143.8 0.100098ZM149 8.30011C149 11.3001 146.8 13.5001 143.8 13.5001C140.8 13.5001 138.6 11.3001 138.6 8.30011C138.6 5.30011 140.8 3.1001 143.8 3.1001C146.7 3.1001 149 5.40011 149 8.30011Z"
      />
      <path
        fill="#082471"
        d="M165 0.100098C160.2 0.100098 156.7 3.60011 156.7 8.30011C156.7 13.0001 160.3 16.5001 165 16.5001C169.7 16.5001 173.3 13.0001 173.3 8.30011C173.4 3.60011 169.8 0.100098 165 0.100098ZM170.2 8.30011C170.2 11.3001 168 13.5001 165 13.5001C162 13.5001 159.8 11.3001 159.8 8.30011C159.8 5.30011 162 3.1001 165 3.1001C168 3.1001 170.2 5.40011 170.2 8.30011Z"
      />
      <path
        fill="#082471"
        d="M178.9 16.1999H182V9.99991H187.8V7.09991H182V3.3999H188.3V0.399902H178.9V16.1999Z"
      />
      <path
        fill="#082471"
        d="M196.8 0.399902H193.7V16.1999H196.8V0.399902Z"
      />
      <path
        fill="#082471"
        d="M213.4 10.4999L206 0.399902H203.3V16.1999H206.4V5.99991L213.9 16.1999H216.4V0.399902H213.4V10.4999Z"
      />
      <path
        fill="#082471"
        d="M229.3 7.89999V10.7H234.6C233.9 12.4 232.2 13.5 230 13.5C227 13.5 225 11.4 225 8.30002C225 5.30002 227 3.10001 230 3.10001C232.2 3.10001 234 4.30001 234.6 6.10001L234.7 6.30002H237.9L237.8 5.89999C237.1 2.39999 233.9 0 230 0C225.4 0 222 3.50001 222 8.20001C222 13 225.4 16.4 230.1 16.4C234.7 16.4 238 13 238.1 8.10001V7.80002H229.3V7.89999Z"
      />
      <path
        fill="#082471"
        d="M5.07593 32.8209V34.4071H0V26.3174H4.9173V27.9036H1.74485V29.4898H4.60006V31.0761H1.74485V32.8209H5.07593Z"
      />
      <path
        fill="#082471"
        d="M12.6898 34.4071L11.1035 31.5519L9.51732 34.4071H7.61386L10.1518 30.1243L7.77249 26.3174H9.67596L11.1035 28.8553L12.5312 26.3174H14.4346L12.0553 30.2829L14.5933 34.5658H12.6898V34.4071Z"
      />
      <path
        fill="#082471"
        d="M16.4967 30.2828C16.4967 27.9034 18.2416 26 20.6209 26C22.5244 26 24.1106 27.269 24.5865 29.1724H22.8416C22.5244 28.2207 21.7313 27.5862 20.6209 27.5862C19.1933 27.5862 18.2416 28.6965 18.2416 30.1241C18.2416 31.5517 19.3519 32.6621 20.6209 32.6621C21.7313 32.6621 22.5244 32.0276 22.8416 30.9173H24.5865C24.2692 32.9793 22.683 34.2483 20.6209 34.2483C18.2416 34.5656 16.4967 32.6621 16.4967 30.2828Z"
      />
      <path
        fill="#082471"
        d="M32.6762 32.8209V34.4071H27.6003V26.3174H32.5176V27.9036H29.3451V29.4898H32.2003V31.0761H29.3451V32.8209H32.6762Z"
      />
      <path
        fill="#082471"
        d="M40.7659 32.8209V34.4071H35.8486V26.3174H37.5935V32.8209H40.7659Z"
      />
      <path
        fill="#082471"
        d="M48.5384 32.8209V34.4071H43.6211V26.3174H45.366V32.8209H48.5384Z"
      />
      <path
        fill="#082471"
        d="M56.4696 32.8209V34.4071H51.3936V26.3174H56.3109V27.9036H53.1385V29.4898H55.9937V31.0761H53.1385V32.8209H56.4696Z"
      />
      <path
        fill="#082471"
        d="M66.6214 26.3174V34.4071H65.1938L61.5455 29.3312V34.4071H59.8006V26.3174H61.3869L64.8765 31.2347V26.3174H66.6214Z"
      />
      <path
        fill="#082471"
        d="M69.7938 30.2828C69.7938 27.9034 71.5386 26 73.918 26C75.8214 26 77.4077 27.269 77.8835 29.1724H76.1387C75.8214 28.2207 75.0283 27.5862 73.918 27.5862C72.4904 27.5862 71.5386 28.6965 71.5386 30.1241C71.5386 31.5517 72.649 32.6621 73.918 32.6621C75.0283 32.6621 75.8214 32.0276 76.1387 30.9173H77.8835C77.5663 32.9793 75.9801 34.2483 73.918 34.2483C71.5386 34.5656 69.7938 32.6621 69.7938 30.2828Z"
      />
      <path
        fill="#082471"
        d="M85.9733 32.8209V34.4071H81.056V26.3174H85.9733V27.9036H82.8009V29.4898H85.656V31.0761H82.8009V32.8209H85.9733Z"
      />
      <path
        fill="#082471"
        d="M98.5044 34.4071C98.3458 34.2484 98.1872 34.0898 98.0286 33.9312C97.3941 34.4071 96.601 34.5657 95.8079 34.5657C94.2216 34.5657 93.2699 33.614 93.2699 32.1864C93.2699 31.076 93.9044 30.4415 94.6975 29.807C94.063 28.8553 93.9044 28.3794 93.9044 28.0622C93.9044 26.9518 94.8561 26.1587 96.1251 26.1587C97.5527 26.1587 98.3458 26.9518 98.3458 28.0622C98.3458 29.1725 97.3941 29.807 96.601 30.2829C96.9182 30.7587 97.3941 31.2346 97.87 31.8691C98.0286 31.5519 98.1872 31.076 98.1872 30.6001H99.6148C99.6148 31.5519 99.2975 32.345 98.8217 32.9795C99.1389 33.4553 99.6148 33.9312 100.091 34.4071H98.5044ZM97.0768 32.8208C96.4424 32.1864 95.9665 31.5519 95.4906 31.076C95.0148 31.3932 94.8561 31.7105 94.8561 32.1864C94.8561 32.8209 95.332 33.1381 95.9665 33.1381C96.4423 33.1381 96.7596 32.9795 97.0768 32.8208ZM95.4906 28.0622C95.4906 28.3794 95.6492 28.6967 95.9665 29.1726C96.601 28.8553 97.0768 28.538 97.0768 28.0622C97.0768 27.5863 96.7596 27.4277 96.2837 27.4277C95.8079 27.4277 95.4906 27.5863 95.4906 28.0622Z"
      />
      <path
        fill="#082471"
        d="M113.891 35.2001L112.939 34.0897C112.305 34.4069 111.67 34.5656 111.036 34.5656C108.498 34.5656 106.753 32.6621 106.753 30.2828C106.753 27.9034 108.656 26 111.036 26C113.574 26 115.318 27.7448 115.318 30.2828C115.318 31.5518 114.843 32.6621 114.049 33.2966L115.636 35.2001H113.891ZM111.829 32.8207L110.242 30.9173H111.987L112.939 32.0276C113.415 31.5518 113.574 30.9173 113.574 30.2828C113.574 28.8552 112.463 27.7448 111.036 27.7448C109.608 27.7448 108.498 28.8552 108.498 30.2828C108.498 31.7104 109.608 32.8207 111.036 32.8207C111.353 32.8207 111.67 32.8207 111.829 32.8207Z"
      />
      <path
        fill="#082471"
        d="M118.491 31.3933V26.3174H120.236V31.2347C120.236 32.1864 120.87 32.8209 121.822 32.8209C122.774 32.8209 123.408 32.1864 123.408 31.2347V26.3174H125.153V31.3933C125.153 33.2968 123.725 34.5658 121.822 34.5658C119.918 34.5658 118.491 33.2968 118.491 31.3933Z"
      />
      <path
        fill="#082471"
        d="M133.243 32.8209H130.07L129.436 34.4071H127.532L130.705 26.3174H132.291L135.622 34.4071H133.719L133.243 32.8209ZM130.546 31.2347H132.608L131.498 28.3795L130.546 31.2347Z"
      />
      <path
        fill="#082471"
        d="M143.395 32.8209V34.4071H138.477V26.3174H140.222V32.8209H143.395Z"
      />
      <path
        fill="#082471"
        d="M146.408 26.3174H148.153V34.4071H146.408V26.3174Z"
      />
      <path
        fill="#082471"
        d="M157.353 27.9036H155.133V34.4071H153.388V27.9036H151.167V26.3174H157.512V27.9036H157.353Z"
      />
      <path
        fill="#082471"
        d="M164.174 31.076V34.4071H162.429V31.076L159.574 26.1587H161.477L163.381 29.4898L165.284 26.1587H167.188L164.174 31.076Z"
      />
    </Icon>
  )
}
