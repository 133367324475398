import * as React from "react";
import {
  Button,
  Grid,
  GridItem,
  Heading,
  Link,
  VStack
} from "@chakra-ui/react";


export const ErrorHero: React.FC = () => {
  return (
    <VStack
      spacing={{
        base: '30px',
        lg: '40px'
      }}
      w="100%"
    >
      <Grid
        templateColumns={{
          base: '1fr',
          lg: '1fr 1fr'
        }}
        gap={{
          base: '30px',
          lg: '0'
        }}
        w="100%"
        maxH={{
          lg: '406px',
          xl: '483px'
        }}
        h="100%"
      >
        <GridItem>
          <VStack
            justify="center"
            align="flex-start"
            spacing="24px"
            w="100%"
            h="100%"
          >
            <Heading
              as="h1"
              maxW="500px"
              w="100%"
              color="text.primary"
              fontSize="36px"
              fontWeight={ 700 }
            >
              404: Page Not Found
            </Heading>
            <Heading
              as="h3"
              color="text.primary"
              fontSize="16px"
              fontWeight={ 400 }
            >
              Looks like the page that you requested doesn't exist.
            </Heading>
            <Button
              as={ Link }
              href="/"
              variant="solid"
              colorScheme="primary"
              size="lg"
              border="1px"
              borderColor="primary.900"
              borderRadius="24px"
              _hover={{
                background: 'transparent',
                color: 'primary.900',
                textDecoration: 'none'
              }}
            >
              Back to Home
            </Button>
          </VStack>
        </GridItem>
        <GridItem>
          
        </GridItem>
      </Grid>
    </VStack>
  )
}
