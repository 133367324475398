import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: string
  h?: string
  color?: string
  transition?: string
  _hover?: object
};


export const Google: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 13 13" { ...props }>
      <path
        fill="currentColor"
        d="M12.8566 5.14583H6.5V7.85417H10.0134C9.467 9.27419 8.11264 10.2917 6.5 10.2917C4.40593 10.2917 2.70833 8.59407 2.70833 6.5C2.70833 4.4059 4.40593 2.70833 6.5 2.70833C7.25934 2.70833 7.9592 2.94161 8.55211 3.32621C8.64286 3.38519 8.73649 3.44056 8.82175 3.50678L10.7919 1.62722L10.7474 1.59303C9.60751 0.605077 8.12692 0 6.5 0C2.91014 0 0 2.91014 0 6.5C0 10.0898 2.91014 13 6.5 13C9.81374 13 12.5425 10.5185 12.9437 7.31349C12.9776 7.04719 13 6.77553 13 6.5C13 6.03533 12.9493 5.58286 12.8566 5.14583Z"
      />
    </Icon>
  )
}
