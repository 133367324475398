import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
  color?: string | undefined
}


export const License: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 62 62" { ...props }>
      <circle
        stroke="currentColor"
        fill="none"
        cx="31"
        cy="31"
        r="30"
      />
      <path
        fill="currentColor"
        d="M42.3947 28.1119V21.2796C42.3947 19.4758 40.9189 18 39.1151 18H22.6623C20.6398 18 19 19.6398 19 21.6076V39.8642C19 40.1921 19.2187 40.4108 19.5467 40.4108H22.8263V43.6904C22.8263 44.1824 23.4275 44.4011 23.7554 44.0731L25.0673 42.5973L26.3791 44.0731C26.7071 44.4558 27.3083 44.1824 27.3083 43.6904V40.4108H37.3659C37.6938 40.4108 37.9125 40.1921 37.9125 39.8642V28.6587H41.6841C42.1213 28.6587 42.3947 28.44 42.3947 28.112V28.1119ZM26.379 42.2691L25.6138 41.3944C25.3951 41.1757 25.0126 41.1757 24.7939 41.3944L24.0288 42.2691V37.2405C24.4114 37.3497 24.7939 37.4045 25.2313 37.4045C25.6686 37.4045 26.0512 37.3497 26.4339 37.2405L26.4337 42.2691H26.379ZM26.707 35.9286C26.215 36.1473 25.7231 36.3113 25.1764 36.3113C23.3179 36.3113 21.8421 34.8355 21.8421 32.977C21.8421 31.1185 23.3179 29.6427 25.1764 29.6427C27.0349 29.6427 28.5108 31.1185 28.5108 32.977C28.5108 34.2888 27.7456 35.382 26.707 35.9286ZM36.9286 39.3175H27.4725V36.7485C28.7296 35.9834 29.6043 34.5621 29.6043 32.977C29.6043 30.5173 27.6365 28.5495 25.1768 28.5495C22.7171 28.5495 20.7493 30.5718 20.7493 33.0315C20.7493 34.6166 21.624 36.0379 22.8811 36.8031V39.3175H20.1481L20.148 21.6077C20.0934 20.2411 21.2413 19.0932 22.6077 19.0932H36.9287L36.9286 39.3175ZM41.3013 27.5658H38.0764V19.0931H39.115C40.3176 19.0931 41.3015 20.077 41.3015 21.2796L41.3013 27.5658Z"
      />
      <path
        fill="currentColor"
        d="M21.6784 22.4822C21.6784 22.8102 21.8971 23.0289 22.2251 23.0289H34.7967C35.1247 23.0289 35.3434 22.8102 35.3434 22.4822C35.3434 22.1543 35.1247 21.9356 34.7967 21.9356H22.2251C21.9516 21.9356 21.6784 22.1543 21.6784 22.4822Z"
      />
      <path
        fill="currentColor"
        d="M34.7968 26.035H22.2252C21.8972 26.035 21.6785 26.2537 21.6785 26.5817C21.6785 26.9096 21.8972 27.1283 22.2252 27.1283H34.7968C35.1248 27.1283 35.3435 26.9096 35.3435 26.5817C35.3435 26.2537 35.1248 26.035 34.7968 26.035Z"
      />
    </Icon>
  )
}
