import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
  color?: string | undefined
}


export const Phone: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 18 18" { ...props }>
      <path
        fill="currentColor"
        d="M0.214043 0.369568C-0.501222 4.17174 0.488934 9.46545 4.51174 13.4883C8.53455 17.5111 13.8283 18.5012 17.6304 17.7859C17.7419 17.7653 17.8415 17.7034 17.9095 17.6128C17.9776 17.5221 18.0091 17.4092 17.9977 17.2964L17.5327 12.7059C17.5231 12.6112 17.4837 12.5219 17.4202 12.4509C17.3567 12.38 17.2724 12.3309 17.1793 12.3109L11.9818 11.1914C11.8895 11.1715 11.7933 11.1811 11.7068 11.2189C11.6203 11.2566 11.5478 11.3205 11.4996 11.4017L10.2736 13.4647C10.2425 13.5165 10.2013 13.5616 10.1524 13.5973C10.1036 13.633 10.0481 13.6585 9.98923 13.6724C9.93036 13.6862 9.8693 13.6882 9.80967 13.6781C9.75004 13.6679 9.69304 13.646 9.64203 13.6135C8.58474 12.9463 7.60604 12.162 6.72448 11.2755C5.83799 10.3939 5.05372 9.41525 4.38652 8.35796C4.35401 8.30696 4.33205 8.24995 4.32193 8.19032C4.31182 8.13069 4.31375 8.06964 4.32763 8.01076C4.3415 7.95189 4.36703 7.8964 4.4027 7.84755C4.43838 7.79871 4.48348 7.75751 4.53534 7.72639L6.59832 6.5004C6.67946 6.45218 6.74341 6.3797 6.78114 6.29318C6.81887 6.20666 6.82848 6.11048 6.80861 6.01821L5.68912 0.82074C5.66907 0.727645 5.62004 0.643296 5.54907 0.579802C5.47809 0.516309 5.38883 0.476937 5.29408 0.467338L0.703645 0.00225616C0.590846 -0.00905255 0.477915 0.0224284 0.387233 0.09046C0.296552 0.158492 0.234738 0.258108 0.214043 0.369568Z"
      />
    </Icon>
  )
}
