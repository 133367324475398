import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
  color?: string | undefined
}


export const Shield: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 62 62" { ...props }>
      <circle
        stroke="currentColor"
        fill="none"
        cx="31"
        cy="31"
        r="30"
      />
      <path
        fill="currentColor"
        d="M30.9824 43.7037C30.8121 43.7037 30.7439 43.6016 30.676 43.5335C30.0973 42.9208 28.2248 41.1504 23.3224 38.5289C19.135 36.2819 17.9433 30.971 19.9519 23.5833V23.5494L20.5988 20.8939C20.6328 20.7918 20.7009 20.6897 20.8372 20.6555C20.8712 20.6555 25.1268 19.2256 30.9144 18H31.0505C36.838 19.2256 41.0937 20.6215 41.1277 20.6555C41.2298 20.6894 41.3319 20.7916 41.3661 20.8939L42.047 23.6175C44.0555 31.0052 42.8641 36.3162 38.7107 38.5631C33.8082 41.1846 31.9017 42.9547 31.323 43.5676C31.2204 43.6016 31.1183 43.6698 30.9822 43.7037L30.9824 43.7037ZM21.2456 21.2343L20.6329 23.7537C18.6923 30.8009 19.7816 35.8395 23.6287 37.9162C28.0545 40.2993 30.0971 41.9676 30.9823 42.8526C31.8674 41.9675 33.9101 40.2993 38.3359 37.9162C42.183 35.8395 43.2723 30.8009 41.3659 23.7537V23.6855L40.719 21.2343C39.8679 20.9619 36.0208 19.7363 30.9822 18.681C25.9095 19.7365 22.0966 20.928 21.2455 21.2343L21.2456 21.2343Z"
      />
      <path
        fill="currentColor"
        d="M35.3741 36.2139C35.306 36.2139 35.238 36.18 35.1699 36.1458L30.9825 33.0819L26.7951 36.1458C26.659 36.2479 26.5227 36.2479 26.3866 36.1458C26.2505 36.0436 26.2163 35.9073 26.2505 35.7713L27.8846 30.8349L23.663 27.8049C23.5269 27.7028 23.4927 27.5665 23.5269 27.4304C23.5609 27.2943 23.6972 27.192 23.8675 27.192L29.0758 27.2262L30.642 22.2898C30.6759 22.1537 30.8122 22.0513 30.9825 22.0513C31.1528 22.0513 31.2549 22.1534 31.3231 22.2898L32.8892 27.2262L38.0641 27.1922C38.2001 27.1922 38.3365 27.2943 38.4046 27.4306C38.4386 27.5667 38.4046 27.737 38.2685 27.8051L34.047 30.8351L35.647 35.7033C35.681 35.7373 35.681 35.8055 35.681 35.8736C35.7147 36.0437 35.5445 36.2139 35.3742 36.2139L35.3741 36.2139ZM30.9825 32.2987C31.0507 32.2987 31.1186 32.3326 31.1867 32.3668L34.6933 34.9542L33.3317 30.8007C33.2977 30.6646 33.3317 30.4944 33.4677 30.4262L37.0082 27.8729L32.6505 27.9068C32.5145 27.9068 32.3782 27.8047 32.31 27.6684L30.9823 23.515L29.6545 27.6684C29.6206 27.8045 29.4842 27.9068 29.314 27.9068L24.9563 27.8729L28.497 30.4265C28.6331 30.5286 28.6673 30.6649 28.6331 30.801L27.2714 34.9544L30.778 32.3669C30.8462 32.3329 30.9143 32.2987 30.9825 32.2987L30.9825 32.2987Z"
      />
    </Icon>
  )
}
