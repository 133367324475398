import * as React from "react";
import {
  Flex
} from "@chakra-ui/react";

import { ScalableVectorGraphicsFactory } from "../../ScalableVectorGraphics";


interface Props {
  node: any
}


export const Client: React.FC<Props> = ( props ) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      minW="500px"
      w="500px"
      h="70px"
    >
      <ScalableVectorGraphicsFactory
        svg={ props.node.frontmatter.svg }
        w="auto"
        h="100%"
      />
    </Flex>
  )
}
