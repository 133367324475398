import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
  color?: string | undefined
}


export const Free: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 62 62" { ...props }>
      <circle
        stroke="currentColor"
        fill="none"
        cx="31"
        cy="31"
        r="30"
      />
      <path
        fill="currentColor"
        d="M21.6095 26.5783C21.3657 26.5783 21.1708 26.7733 21.1708 27.017V34.9799C21.1708 35.2236 21.3657 35.4186 21.6095 35.4186C21.8533 35.4186 22.0482 35.2236 22.0482 34.9799V27.017C22.0482 26.7773 21.8533 26.5783 21.6095 26.5783V26.5783Z"
      />
      <path
        fill="currentColor"
        d="M40.2369 35.4187C40.4807 35.4187 40.6757 35.2237 40.6757 34.98V27.0171C40.6757 26.7734 40.4807 26.5784 40.2369 26.5784C39.9932 26.5784 39.7982 26.7734 39.7982 27.0171V34.98C39.798 35.2237 39.9932 35.4187 40.2369 35.4187Z"
      />
      <path
        fill="currentColor"
        d="M43.8687 18.128C43.6981 17.9573 43.4179 17.9573 43.2472 18.128L37.2182 24.157H19.1516C18.9078 24.157 18.7129 24.352 18.7129 24.5957V37.4013C18.7129 37.645 18.9078 37.84 19.1516 37.84H23.5353L18.128 43.2473C17.9573 43.418 17.9573 43.6982 18.128 43.8688C18.2132 43.9543 18.323 43.9989 18.4367 43.9989C18.5504 43.9989 18.6601 43.9543 18.7454 43.8688L24.7744 37.8398H42.626C42.8697 37.8398 43.0647 37.6449 43.0647 37.4011V24.5956C43.0647 24.3518 42.8697 24.1569 42.626 24.1569L38.4617 24.1567L43.869 18.7494C44.0437 18.5787 44.0437 18.2985 43.869 18.1278L43.8687 18.128ZM19.5902 36.9625V25.0345H36.3405L34.0979 27.2772C33.2569 26.5744 32.1762 26.1518 30.9981 26.1518C28.3249 26.1518 26.147 28.3253 26.147 30.9987C26.147 32.1768 26.5696 33.2574 27.2724 34.0985L24.4082 36.9627L19.5902 36.9625ZM27.9022 33.4728C27.3577 32.7943 27.0288 31.933 27.0288 30.9986C27.0288 28.8087 28.8082 27.0293 30.998 27.0293C31.9324 27.0293 32.7938 27.3583 33.4723 27.8988L27.9022 33.4728ZM34.0937 28.5243C34.6382 29.2027 34.9672 30.0641 34.9672 31.0025C34.9672 33.1923 33.1878 34.9717 30.9979 34.9717C30.0595 34.9717 29.1982 34.6427 28.5197 34.0983L34.0937 28.5243ZM42.1907 25.0345V36.9625H25.6559L27.8986 34.7199C28.7395 35.4227 29.8202 35.8453 31.0025 35.8453C33.6757 35.8453 35.8494 33.6718 35.8494 30.9984C35.8494 29.8202 35.4268 28.7396 34.724 27.8986L37.5842 25.0384L42.1907 25.0345Z"
      />
    </Icon>
  )
}
