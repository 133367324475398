import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
  color?: string | undefined
}


export const Pin: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 16 22" { ...props }>
      <path
        fill="currentColor"
        d="M7.66816 0C11.9032 0 15.3365 3.43328 15.3365 7.66839C15.3365 9.19486 14.9962 10.1844 14.2006 11.3606L7.66827 21.0171L1.13594 11.3606C0.340139 10.1844 -1.90735e-05 9.19486 -1.90735e-05 7.66839C-1.90735e-05 3.43333 3.43326 0 7.66837 0H7.66816ZM7.66816 3.9761C5.78591 3.9761 4.26004 5.50196 4.26004 7.38422C4.26004 9.26647 5.78591 10.7923 7.66816 10.7923C9.55042 10.7923 11.0763 9.26647 11.0763 7.38422C11.0763 5.50196 9.55042 3.9761 7.66816 3.9761Z"
      />
    </Icon>
  )
}
