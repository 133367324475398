import * as React from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Link,
  Text,
  VStack
} from "@chakra-ui/react";

import { Socials } from "../Navigation";
import {
  DestinyText,
  SatisfactionGuaranteed
} from "../ScalableVectorGraphics";

import { Copyright } from "./Copyright";


export const Footer: React.FC = () => {
  return (
    <>
      <Box
        w="100%"
        bg="background.azureishwhite"
        p={{
          base: '40px 0',
          lg: '80px 0'
        }}
      >
        <Container
          maxW={{
            base: '100%',
            sm: '640px',
            md: '768px',
            lg: '1024px',
            xl: '1280px'
          }}
          w="100%"
          padding="0 1rem"
        >
          <Flex
            direction={{
              base: 'column',
              lg: 'row'
            }}
            justifyContent={{
              lg: 'space-between'
            }}
            align="flex-start"
            gap={{
              base: '24px',
              lg: '0'
            }}
          >
            <VStack
              justify="flex-start"
              align="flex-start"
              spacing="24px"
            >
              <DestinyText
                w="auto"
                h="36px"
              />
              <Socials />
            </VStack>
            <Grid
              templateColumns={{
                base: '1fr',
                lg: '1fr 1fr 1fr'
              }}
              gap={{
                base: '24px',
                lg: '32px'
              }}
            >
              <GridItem>
                <VStack
                  justify="flex-start"
                  align="flex-start"
                  spacing="12px"
                >
                  <Heading
                    as="h3"
                    color="text.primary"
                    fontSize="18px"
                    fontWeight={ 700 }
                  >
                    Address
                  </Heading>
                  <VStack
                    justify="flex-start"
                    align="flex-start"
                    spacing="0"
                  >
                    <Text
                      color="text.primary"
                      fontSize="16px"
                      fontWeight={ 400 }
                    >
                      19 Kenview Blvd, Unit 34,
                    </Text>
                    <Text
                      color="text.primary"
                      fontSize="16px"
                      fontWeight={ 400 }
                    >
                      Brampton, ON L6T 5G6
                    </Text>
                  </VStack>
                </VStack>
              </GridItem>
              <GridItem>
                <VStack
                  justify="flex-start"
                  align="flex-start"
                  spacing="12px"
                >
                  <Heading
                    as="h3"
                    color="text.primary"
                    fontSize="18px"
                    fontWeight={ 700 }
                  >
                    Phone
                  </Heading>
                  <VStack
                    justify="flex-start"
                    align="flex-start"
                    spacing="0"
                  >
                    <Text
                      color="text.primary"
                      fontSize="16px"
                      fontWeight={ 400 }
                    >
                      Tel:&nbsp;
                      <Button
                        as={ Link }
                        href="tel:+19059700888"
                        variant="link"
                        color="primary.900"
                        fontSize="16px"
                        fontWeight={ 400 }
                      >
                        905-970-0888
                      </Button>
                    </Text>
                    <Text
                      color="text.primary"
                      fontSize="16px"
                      fontWeight={ 400 }
                    >
                      Mob:&nbsp;
                      <Button
                        as={ Link }
                        href="tel:+14168753551"
                        variant="link"
                        color="primary.900"
                        fontSize="16px"
                        fontWeight={ 400 }
                      >
                        416-875-3551
                      </Button>
                    </Text>
                  </VStack>
                </VStack>
              </GridItem>
              <GridItem>
                <VStack
                  justify="flex-start"
                  align="flex-start"
                  spacing="12px"
                >
                  <Heading
                    as="h3"
                    color="text.primary"
                    fontSize="18px"
                    fontWeight={ 700 }
                  >
                    Services
                  </Heading>
                  <VStack
                    justify="flex-start"
                    align="flex-start"
                    spacing="0"
                  >
                    <Text
                      color="text.primary"
                      fontSize="16px"
                      fontWeight={ 400 }
                    >
                      Industrial Roofing
                    </Text>
                    <Text
                      color="text.primary"
                      fontSize="16px"
                      fontWeight={ 400 }
                    >
                      Commercial Roofing
                    </Text>
                    <Text
                      color="text.primary"
                      fontSize="16px"
                      fontWeight={ 400 }
                    >
                      Residential Roofing
                    </Text>
                  </VStack>
                </VStack>
              </GridItem>
            </Grid>
            <Flex
              justifyContent={{
                base: 'flex-start',
                lg: 'flex-end'
              }}
            >
              <SatisfactionGuaranteed
                w="105px"
                h="auto"
                color="primary.900"
              />
            </Flex>
          </Flex>
        </Container>
      </Box>
      <Copyright />
    </>
  )
}
