import * as React from "react";
import { GatsbyImage, withArtDirection } from "gatsby-plugin-image";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  VStack
} from "@chakra-ui/react";

import { Ticker } from "../Ticker";


interface Props {
  node: any
}


export const IndexHero: React.FC<Props> = ( props ) => {
  const images = withArtDirection( props.node.hero_image.desktop.childImageSharp.gatsbyImageData, [
    {
      media: '( max-width: 1024px )',
      image: props.node.hero_image.mobile.childImageSharp.gatsbyImageData
    }
  ]);

  return (
    <VStack
      spacing={{
        base: '30px',
        lg: '40px'
      }}
      w="100%"
    >
      <Grid
        templateColumns={{
          base: '1fr',
          lg: '1fr 1fr'
        }}
        gap={{
          base: '30px',
          lg: '0'
        }}
        w="100%"
        maxH={{
          lg: '406px',
          xl: '483px'
        }}
        h="100%"
      >
        <GridItem>
          <VStack
            justify="center"
            align="flex-start"
            spacing="24px"
            w="100%"
            h="100%"
          >
            <Stack
              direction={{
                base: 'column',
                sm: 'row'
              }}
              justify="flex-start"
              align={{
                base: 'flex-start',
                sm: 'center'
              }}
              spacing="12px"
              w="100%"
              h="auto"
            >
              <HStack
                spacing="12px"
              >
                <Heading
                  as="h3"
                  color="text.primary"
                  fontSize="16px"
                  fontWeight={ 400 }
                >
                  Commercial
                </Heading>
                <Text>
                  &middot;
                </Text>
                <Heading
                  as="h3"
                  color="text.primary"
                  fontSize="16px"
                  fontWeight={ 400 }
                >
                  Industrial
                </Heading>
              </HStack>
              <Text
                display={{
                  base: 'none',
                  sm: 'block'
                }}
              >
                &middot;
              </Text>
              <HStack
                spacing="12px"
              >
                <Heading
                  as="h3"
                  color="text.primary"
                  fontSize="16px"
                  fontWeight={ 400 }
                >
                  Residential
                </Heading>
                <Text>
                  &middot;
                </Text>
                <Heading
                  as="h3"
                  color="text.primary"
                  fontSize="16px"
                  fontWeight={ 400 }
                >
                  Metal
                </Heading>
              </HStack>
            </Stack>
            <Heading
              as="h1"
              maxW="500px"
              w="100%"
              color="text.primary"
              fontSize="36px"
              fontWeight={ 700 }
            >
              Your Local Roofing Company Serving All of Ontario
            </Heading>
            <Button
              as={ Link }
              href="#contact"
              variant="solid"
              colorScheme="primary"
              size="lg"
              border="1px"
              borderColor="primary.900"
              borderRadius="24px"
              _hover={{
                background: 'transparent',
                color: 'primary.900',
                textDecoration: 'none'
              }}
            >
              Get in Touch
            </Button>
          </VStack>
        </GridItem>
        <GridItem>
          <Flex
            justifyContent="center"
            alignItems="bottom"
            w="100%"
            h="100%"
          >
            <Image
              as={ GatsbyImage }
              image={ images }
              maxW={{
                base: '400px',
                xl: '475px'
              }}
              w={{
                base: '100%',
                sm: '400px',
                xl: '475px'
              }}
              h="auto"
            />
          </Flex>
        </GridItem>
      </Grid>
      <Box
        w="100%"
      >
        <Ticker
          nodes={ props.node.benefits }
          template="benefit"
          itemWidth={ 250 }
        />
      </Box>
    </VStack>
  )
}
