import * as React from "react";
import {
  Box,
  Heading,
  Text
} from "@chakra-ui/react";


interface Props {
  heading: string
  lastUpdated: string
}


export const LegalHero: React.FC<Props> = ( props ) => {
  return (
    <Box
      position="relative"
      w="100%"
    >
      <Heading
        as="h1"
        maxW="505px"
        fontSize="48px"
        fontWeight={ 700 }
      >
        { props.heading }
      </Heading>
      <Text
        w="100%"
        fontSize="18px"
        fontWeight={ 400 }
      >
        Last Updated: { props.lastUpdated }
      </Text>
    </Box>
  )
}
