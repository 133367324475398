import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
}


export const Hilton: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 197 93" { ...props }>
      <path
        fill="#AAAD00"
        d="M182.714 82.8173V71.1628L159.136 65.6309L174.55 49.9482C179.008 44.3626 182.23 37.9714 182.123 28.7874C180.995 13.5881 172.241 12.4602 165.474 13.5881C156.289 16.6494 149.576 23.8462 148.878 33.4599L157.901 33.0303C158.384 28.2503 161.392 24.5444 165.581 23.5777C169.502 22.6647 172.993 25.1889 173.046 30.7208C173.1 35.3934 170.307 39.2604 167.944 42.268L148.556 61.6565V70.7331L182.714 82.8173ZM188.89 0V92.431L142.916 74.7075V18.0995L188.89 0Z"
      />
      <path
        fill="white"
        d="M182.714 71.2164V82.871L148.663 70.733V61.6564L168.052 42.2679C170.361 39.2603 173.208 35.3933 173.154 30.7208C173.1 25.2426 169.609 22.6646 165.688 23.5776C161.446 24.5444 158.492 28.2502 158.008 33.0302L148.985 33.4598C149.684 23.8462 156.397 16.6493 165.581 13.588C172.348 12.4601 181.103 13.6417 182.23 28.7873C182.338 38.025 179.115 44.3625 174.658 49.9481L159.19 65.6308L182.714 71.2164Z"
      />
      <path
        fill="#493728"
        d="M136.149 52.9021V58.7025H110.262V18.1532H136.149V23.9536H116.385V35.5008H133.249V40.979H116.385V52.9021H136.149Z"
      />
      <path
        fill="#493728"
        d="M131.315 66.7588L134.323 71.4314V66.7588H135.827V74.3853H134.484L131.477 69.7127V74.3853H130.026V66.7588H131.315Z"
      />
      <path
        fill="#493728"
        d="M121.594 68.4236C121.326 68.7458 121.218 69.1218 121.218 70.5719C121.218 72.022 121.272 72.398 121.594 72.7202C121.809 72.935 122.131 73.0962 122.561 73.0962C122.991 73.0962 123.313 72.935 123.528 72.7202C123.796 72.398 123.904 72.022 123.904 70.5719C123.904 69.1218 123.796 68.7458 123.528 68.4236C123.313 68.2088 122.991 68.0476 122.561 68.0476C122.185 68.0476 121.809 68.2088 121.594 68.4236ZM120.52 67.5106C121.057 66.9735 121.702 66.6512 122.561 66.6512C123.42 66.6512 124.065 66.9735 124.602 67.5106C125.354 68.2625 125.354 69.2292 125.354 70.5719C125.354 71.8609 125.354 72.8276 124.602 73.6333C124.065 74.1703 123.42 74.4926 122.561 74.4926C121.702 74.4926 121.057 74.224 120.52 73.6333C119.768 72.8813 119.768 71.9146 119.768 70.5719C119.768 69.2292 119.715 68.3162 120.52 67.5106Z"
      />
      <path
        fill="#493728"
        d="M113.914 68.1015V74.3853H112.41V68.1015H110.423V66.7588H115.901V68.1015H113.914Z"
      />
      <path
        fill="#493728"
        d="M107.577 73.0426V74.3853H102.582V66.7588H104.086V73.0426H107.577Z"
      />
      <path
        fill="#493728"
        d="M103.441 18.1532V58.7025H97.2647V33.4062H97.1572L88.6714 52.9021H83.5155L75.0834 33.4062H74.9222V58.7025H68.7996V18.1532H74.6L86.2009 44.5774L97.5869 18.1532H103.441Z"
      />
      <path
        fill="#493728"
        d="M97.7481 66.7588H96.2443V74.3853H97.7481V66.7588Z"
      />
      <path
        fill="#493728"
        d="M89.853 69.8738V66.7588H91.3569V74.3853H89.853V71.2165H87.2751V74.3853H85.825V66.7588H87.2751V69.8738H89.853Z"
      />
      <path
        fill="#493728"
        d="M73.9019 71.2703V74.3853H72.4518V71.2703L70.1423 66.7588H71.7536L73.2037 69.8201L74.6001 66.7588H76.2113L73.9019 71.2703Z"
      />
      <path
        fill="#493728"
        d="M56.9302 38.4547C56.9302 30.13 56.5005 29.0021 54.8893 26.9613C53.654 25.35 51.5057 24.0073 48.6055 24.0073C45.7052 24.0073 43.6106 25.35 42.3217 26.9613C40.7104 29.0021 40.2808 30.13 40.2808 38.4547C40.2808 46.7257 40.7104 47.9073 42.3217 49.9482C43.5569 51.5594 45.7052 52.9021 48.6055 52.9021C51.5057 52.9021 53.6003 51.5594 54.8893 49.9482C56.4468 47.9073 56.9302 46.7257 56.9302 38.4547ZM61.2805 25.1352C62.5695 27.4983 63.0528 28.7336 63.0528 38.4547C63.0528 48.1758 62.5695 49.4111 61.2805 51.7742C59.347 55.3189 54.943 59.0785 48.5517 59.0785C42.1605 59.0785 37.8102 55.3189 35.8767 51.7742C34.5877 49.3574 34.1044 48.1758 34.1044 38.4547C34.1044 28.7336 34.534 27.4983 35.8767 25.1352C37.8102 21.5905 42.2142 17.8309 48.5517 17.8309C54.943 17.8309 59.347 21.5905 61.2805 25.1352Z"
      />
      <path
        fill="#493728"
        d="M65.3087 70.4646C65.7921 70.6795 66.3291 71.1628 66.3291 72.1833C66.3291 73.5797 65.3624 74.3853 64.0197 74.3853H60.851V66.7588H63.9123C65.4161 66.7588 66.2754 67.6181 66.2754 68.9071C66.2754 69.7664 65.6847 70.3035 65.3087 70.4646ZM63.8049 69.8738C64.4494 69.8738 64.7716 69.4979 64.7716 69.0145C64.7716 68.4774 64.3957 68.1552 63.8049 68.1552H62.3548V69.9276H63.8049V69.8738ZM64.879 72.1296C64.879 71.5925 64.5568 71.2165 63.8586 71.2165H62.3011V73.0963H63.8586C64.5568 73.0426 64.879 72.6129 64.879 72.1296Z"
      />
      <path
        fill="#493728"
        d="M47.7999 69.82L48.7129 69.9274C49.4111 70.0348 49.8408 70.1959 50.163 70.5182C50.5927 70.8941 50.7538 71.4849 50.7538 72.1294C50.7538 73.5795 49.5185 74.4389 47.9073 74.4389C46.7257 74.4389 45.8664 74.224 45.1145 73.4184L46.0812 72.4517C46.5646 72.935 47.2091 73.0962 47.9073 73.0962C48.7666 73.0962 49.25 72.7739 49.25 72.1831C49.25 71.9146 49.1963 71.6998 49.0352 71.5387C48.874 71.3775 48.7129 71.3238 48.337 71.2701L47.4239 71.1627C46.7794 71.0553 46.2961 70.8404 45.9201 70.5182C45.5442 70.1422 45.383 69.6589 45.383 69.0144C45.383 67.6717 46.4035 66.705 48.0147 66.705C49.0889 66.705 49.8408 66.9735 50.4853 67.5643L49.5185 68.4773C49.0352 67.9939 48.4444 67.9402 47.961 67.9402C47.1554 67.9402 46.7794 68.3699 46.7794 68.907C46.7794 69.1218 46.8331 69.2829 46.9943 69.444C47.2628 69.6589 47.4776 69.7663 47.7999 69.82Z"
      />
      <path
        fill="#493728"
        d="M37.7565 69.8738H40.7641V71.2165H37.7565V73.0426H41.2475V74.3853H36.2527V66.7588H41.2475V68.1015H37.7565V69.8738Z"
      />
      <path
        fill="#493728"
        d="M29.9689 68.1015V74.3853H28.4651V68.1015H26.4779V66.7588H31.9561V68.1015H29.9689Z"
      />
      <path
        fill="#493728"
        d="M28.3577 18.1532V58.7025H22.235V40.979H6.12268V58.7025H0V18.1532H6.12268V35.5008H22.235V18.1532H28.3577Z"
      />
      <path
        fill="#493728"
        d="M22.1813 66.7588H20.6775V74.3853H22.1813V66.7588Z"
      />
      <path
        fill="#493728"
        d="M15.8975 71.7536C15.8975 73.3649 14.6622 74.439 13.1047 74.439C11.5472 74.439 10.3119 73.3649 10.3119 71.7536V66.7588H11.8157V71.6999C11.8157 72.5592 12.3528 73.0963 13.1047 73.0963C13.9103 73.0963 14.4474 72.5592 14.4474 71.6999V66.7588H15.8975V71.7536Z"
      />
      <path
        fill="#493728"
        d="M5.31708 70.5182C5.74674 70.8941 5.90787 71.4849 5.90787 72.1294C5.90787 73.5795 4.67259 74.4389 3.06136 74.4389C1.93349 74.4389 1.02046 74.224 0.268555 73.4184L1.2353 72.4517C1.71867 72.935 2.36316 73.0962 3.06136 73.0962C3.92068 73.0962 4.40405 72.7739 4.40405 72.1831C4.40405 71.9146 4.35034 71.6998 4.18922 71.5387C4.0281 71.3775 3.86698 71.3238 3.54473 71.2701L2.63169 71.1627C1.9872 71.0553 1.50383 70.8404 1.18158 70.5182C0.805631 70.1422 0.644507 69.6589 0.644507 69.0144C0.644507 67.6717 1.66496 66.705 3.27619 66.705C4.29664 66.705 5.10225 66.9735 5.74674 67.5643L4.78 68.4773C4.29663 67.9939 3.70585 67.9402 3.22248 67.9402C2.41686 67.9402 2.04091 68.3699 2.04091 68.907C2.04091 69.1218 2.09462 69.2829 2.25574 69.444C2.41686 69.6052 2.6317 69.7126 2.95394 69.7663L3.86697 69.8737C4.56517 70.0348 4.99483 70.1959 5.31708 70.5182Z"
      />
      <path
        fill="#AAAD00"
        d="M194.368 88.3493H193.885V89.2086H194.368C194.637 89.2086 194.852 88.9938 194.852 88.7789C194.852 88.5641 194.637 88.3493 194.368 88.3493ZM194.852 90.7661L194.261 89.6383H193.885V90.7661H193.348V87.8659H194.422C194.959 87.8659 195.335 88.2956 195.335 88.7252C195.335 89.1549 195.067 89.4234 194.744 89.5308L195.389 90.7661H194.852ZM194.315 87.1677C193.133 87.1677 192.166 88.1344 192.166 89.3697C192.166 90.605 193.133 91.5717 194.315 91.5717C195.496 91.5717 196.463 90.605 196.463 89.3697C196.409 88.1344 195.496 87.1677 194.315 87.1677ZM194.315 92.0014C192.865 92.0014 191.629 90.8198 191.629 89.316C191.629 87.8659 192.811 86.6306 194.315 86.6306C195.818 86.6306 197 87.8122 197 89.316C196.946 90.8198 195.765 92.0014 194.315 92.0014Z"
      />
    </Icon>
  )
}
