import * as React from "react";
import {
  Box
} from "@chakra-ui/react";


interface Props {
  children: React.ReactNode
  id?: string | undefined
  background?: object | string | undefined
  margin?: object | string | undefined
  padding?: object | string | undefined
}


export const SectionWrapper: React.FC<Props> = ( props ) => {
  return (
    <Box
      as="section"
      id={ props.id }
      position="relative"
      w="100%"
      background={ props.background }
      margin={ props.margin }
      padding={ props.padding }
    >
      { props.children }
    </Box>
  )
}
