import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
}


export const Magna: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 720 145" { ...props }>
      <path d="M181.1 42.6H219.1L233.1 95.6H234C234.7 91.2 235.3 86.5 236.6 82.2L248.1 42.6H285.7L302.4 143.4H266.7L261.7 88.2H261C260.3 91.5 259.7 95 258.6 98.2L243.9 143.4H222.3L209.1 100.7C208 96.4 207.1 92.4 206.8 88.1H205.6C205.3 92.1 205.1 96.3 204.7 100.3L200.4 143.4H164.7L181.1 42.6Z" fill="black"/>
      <path d="M332.7 143.4H293.6L329 42.6H369.9L406.2 143.4H367L363.6 130.8H335.7L332.7 143.4ZM357.1 106.9L352.8 89.1C351.7 84.8 351 80.4 350.4 76.1H349.1L342.4 106.9H357.1ZM506.5 84C506.2 100.5 506.2 114.2 494.2 127.1C483.4 138.7 466.8 145 451 145C420 145 394.9 126 394.9 93.6C394.9 60.7 419.3 40.9 451.3 40.9C469 40.9 493.2 49.6 501.2 66.8L466.7 79.4C463.9 74.3 458.6 71.6 452.7 71.6C439.5 71.6 432.2 82.8 432.2 95C432.2 106.1 439.2 116.5 451.2 116.5C457 116.5 464.4 113.8 466.2 107.7H449.5V84H506.5ZM509.3 42.6H544.8L578 98.5H579.1C577.6 90.1 576 81.4 576 72.8V42.6H611.3V143.4H576L543.5 90.4H542.4C543.6 97.4 544.7 103.9 544.7 110.6V143.4H509.4V42.6H509.3Z" fill="black"/>
      <path d="M646.7 143.4H607.6L642.9 42.6H683.8L720 143.4H680.9L677.5 130.8H649.6L646.7 143.4ZM671.1 106.9L666.8 89.1C665.7 84.8 665.1 80.4 664.4 76.1H663L656.3 106.9H671.1ZM82.3 0C72 0 63.7 8.3 63.7 18.5C63.7 28.7 72 37 82.3 37C92.6 37 100.9 28.7 100.9 18.5C100.9 8.3 92.6 0 82.3 0ZM108.3 143.5H146.6L109.7 42.8H81.6L76.5 56.8M0 143.5H57.1L28.5 65.7M63.6 143.5H101.9L65 42.8H37L31.8 56.8" fill="black"/>
    </Icon>
  )
}
