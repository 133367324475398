import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
}


export const Toyota: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 758 125" { ...props }>
      <path d="M72.2283 64.55C72.2283 63.6048 72.2617 61.7485 72.2617 61.7485C44.3719 57.3712 24.2166 45.3603 23.6595 31.2392C16.7929 39.8036 12.8969 49.6111 12.8969 60.0613C12.8969 90.8116 46.6519 116.008 89.5564 118.253C79.6171 111.988 72.2283 90.3083 72.2283 64.55ZM0 62.3049C0 27.8973 42.8181 0 95.6407 0C148.462 0 191.281 27.8973 191.281 62.3049C191.281 96.7125 148.461 124.611 95.6407 124.611C42.8181 124.611 0 96.7117 0 62.3049ZM117.73 46.0262C137.574 42.9219 151.464 35.7985 151.464 27.5092C151.464 16.3837 126.468 7.37511 95.6407 7.37511C64.8107 7.37511 39.8172 16.3844 39.8172 27.5092C39.8172 35.7985 53.7049 42.9219 73.551 46.0262C76.7701 24.4607 85.4459 8.99187 95.6407 8.99187C105.838 8.99187 114.511 24.4599 117.73 46.0262ZM101.725 118.252C144.628 116.008 178.387 90.8109 178.387 60.0605C178.387 49.6111 174.487 39.8036 167.62 31.2385C167.063 45.3596 146.912 57.3705 119.02 61.7478C119.02 61.7478 119.054 63.6041 119.054 64.5493C119.055 90.3076 111.66 111.987 101.725 118.252ZM108.896 47.0806C106.778 33.2486 101.642 23.4844 95.6408 23.4844C89.6391 23.4844 84.5038 33.2486 82.3835 47.0806C91.3414 47.8162 100.168 47.828 108.896 47.0806ZM81.3494 62.8745C81.7 82.9044 87.9608 98.8811 95.6407 98.8811C103.317 98.8811 109.58 82.9052 109.935 62.8745C100.123 63.727 90.5437 63.7264 81.3494 62.8745Z" fill="#E40521"/>
      <path d="M431.744 107.762H452.092V73.6662L487.095 17.9693H463.668L441.918 55.7113L420.172 17.9693H396.738L431.744 73.6662V107.762ZM300.569 62.3049C300.569 36.0062 321.892 14.6858 348.194 14.6858C374.494 14.6858 395.815 36.0054 395.815 62.3049C395.815 88.6067 374.494 109.924 348.194 109.924C321.892 109.924 300.569 88.6067 300.569 62.3049ZM348.194 92.4625C362.875 92.4625 374.782 78.9592 374.782 62.3049C374.782 45.6514 362.876 32.1465 348.194 32.1465C333.51 32.1465 321.603 45.6514 321.603 62.3049C321.603 78.9584 333.51 92.4625 348.194 92.4625ZM267.447 107.762H247.383V34.5266H219.39V17.9693H295.437V34.5266H267.447V107.762ZM689.558 87.5592L681.842 107.762H658.412L695.456 17.9686H720.425L757.474 107.762H734.039L726.323 87.5591L689.558 87.5592ZM708.031 39.5431H707.856L695.316 72.4062H720.565L708.031 39.5431ZM637.297 107.762H617.235V34.5266H589.242V17.9693H665.286V34.5266H637.297V107.762ZM488.022 62.3049C488.022 36.0062 509.346 14.6858 535.639 14.6858C561.945 14.6858 583.267 36.0054 583.267 62.3049C583.267 88.6067 561.945 109.924 535.639 109.924C509.346 109.924 488.022 88.6067 488.022 62.3049ZM535.639 92.4625C550.328 92.4625 562.234 78.9592 562.234 62.3049C562.234 45.6514 550.328 32.1465 535.639 32.1465C520.961 32.1465 509.057 45.6514 509.057 62.3049C509.057 78.9584 520.961 92.4625 535.639 92.4625Z" fill="#E40521"/>
    </Icon>
  )
}
