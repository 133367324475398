import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: string
  h?: string
  color?: string
  transition?: string
  _hover?: object
};


export const Instagram: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 12 12" { ...props }>
      <path
        fill="currentColor"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 1.0815C7.602 1.0815 7.792 1.0875 8.425 1.1165C10.051 1.1905 10.8105 1.962 10.8845 3.576C10.9135 4.2085 10.919 4.3985 10.919 6.0005C10.919 7.603 10.913 7.7925 10.8845 8.425C10.81 10.0375 10.0525 10.8105 8.425 10.8845C7.792 10.9135 7.603 10.9195 6 10.9195C4.398 10.9195 4.208 10.9135 3.5755 10.8845C1.9455 10.81 1.19 10.035 1.116 8.4245C1.087 7.792 1.081 7.6025 1.081 6C1.081 4.398 1.0875 4.2085 1.116 3.5755C1.1905 1.962 1.948 1.19 3.5755 1.116C4.2085 1.0875 4.398 1.0815 6 1.0815ZM6 0C4.3705 0 4.1665 0.007 3.5265 0.036C1.3475 0.136 0.1365 1.345 0.0365 3.526C0.007 4.1665 0 4.3705 0 6C0 7.6295 0.007 7.834 0.036 8.474C0.136 10.653 1.345 11.864 3.526 11.964C4.1665 11.993 4.3705 12 6 12C7.6295 12 7.834 11.993 8.474 11.964C10.651 11.864 11.865 10.655 11.9635 8.474C11.993 7.834 12 7.6295 12 6C12 4.3705 11.993 4.1665 11.964 3.5265C11.866 1.3495 10.6555 0.1365 8.4745 0.0365C7.834 0.007 7.6295 0 6 0ZM6 2.919C4.2985 2.919 2.919 4.2985 2.919 6C2.919 7.7015 4.2985 9.0815 6 9.0815C7.7015 9.0815 9.081 7.702 9.081 6C9.081 4.2985 7.7015 2.919 6 2.919ZM6 8C4.8955 8 4 7.105 4 6C4 4.8955 4.8955 4 6 4C7.1045 4 8 4.8955 8 6C8 7.105 7.1045 8 6 8ZM9.203 2.0775C8.805 2.0775 8.4825 2.4 8.4825 2.7975C8.4825 3.195 8.805 3.5175 9.203 3.5175C9.6005 3.5175 9.9225 3.195 9.9225 2.7975C9.9225 2.4 9.6005 2.0775 9.203 2.0775Z"
      />
    </Icon>
  )
}
