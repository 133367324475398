import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
  color?: string | undefined
}


export const Mail: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 20 14" { ...props }>
      <path
        fill="currentColor"
        d="M19 0H0.99998C0.735025 0.00089286 0.481007 0.106474 0.29375 0.293749C0.106492 0.481024 0.000891431 0.735048 0 0.999981V13C0.000892859 13.265 0.106475 13.519 0.29375 13.7063C0.481025 13.8935 0.735048 13.9991 0.99998 14H19C19.265 13.9991 19.519 13.8935 19.7063 13.7063C19.8935 13.519 19.9991 13.265 20 13V0.999981C19.9991 0.735025 19.8935 0.481007 19.7063 0.293749C19.519 0.106491 19.265 0.000891431 19 0ZM15.54 5.82973L10.55 9.1398C10.3868 9.25231 10.1933 9.31235 9.99509 9.31235C9.79688 9.31235 9.60335 9.2523 9.43996 9.1398L4.46983 5.81968L4.47006 5.8199C4.18188 5.61812 4.02117 5.27972 4.04662 4.92881C4.07229 4.57814 4.28055 4.26675 4.59506 4.10892C4.90934 3.95133 5.28346 3.97053 5.57986 4.15981L9.99998 7.10983L14.4299 4.16987C14.7265 3.97925 15.1013 3.95916 15.4165 4.11697C15.7317 4.27456 15.9404 4.58684 15.9658 4.93817C15.9911 5.28973 15.8292 5.62858 15.54 5.82972L15.54 5.82973Z"
      />
    </Icon>
  )
}
