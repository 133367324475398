import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
}


export const Dollarama: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 400 84" { ...props }>
      <path
        fill="#FCE410"
        d="M0 77.3474H399.949V7.98273H0V77.3474Z"
      />
      <path
        fill="#018150"
        d="M4.17578 73.4037H396.237V11.6946H4.17578V73.4037Z"
      />
      <path
        fill="#FCE410"
        d="M32.7128 58.1829C41.5284 58.1829 47.0961 53.7751 47.0961 41.9436C47.0961 31.7361 41.7604 27.0963 32.9448 27.0963H20.8813V58.1829C21.1133 58.1829 32.7128 58.1829 32.7128 58.1829ZM29.9289 34.52H31.5528C36.4246 34.52 38.2805 36.1439 38.2805 42.6396C38.2805 48.4393 35.9606 50.9912 31.7848 50.9912H29.9289V34.52Z"
      />
      <path
        fill="#FCE410"
        d="M63.6903 26.3563C55.1067 26.3563 49.5389 32.62 49.5389 42.3635C49.5389 52.803 55.3387 58.8347 63.6903 58.8347C71.8099 58.8347 78.0736 52.803 78.0736 42.3635C78.0736 34.2439 73.4338 26.3563 63.6903 26.3563ZM63.6903 34.0119C67.6341 33.7799 69.026 38.4197 69.026 42.5955C69.026 47.6992 67.4021 51.4111 63.6903 51.4111C59.5145 51.4111 58.3545 46.5393 58.3545 42.5955C58.3545 38.6517 59.7465 34.0119 63.6903 34.0119Z"
      />
      <path
        fill="#FCE410"
        d="M82.3352 58.1829V27.0963H91.3828V50.7592H103.678V57.9509H82.3352"
      />
      <path
        fill="#FCE410"
        d="M107.564 58.1829V27.0963H116.612V50.7592H128.907V57.9509H107.564"
      />
      <path
        fill="#FCE410"
        d="M139.068 58.1829L139.996 54.0071H148.812L149.972 58.1829H158.787L149.276 27.0963H140.228L130.484 58.1829H139.068ZM147.42 47.7434H141.852L144.636 36.6079L147.42 47.7434Z"
      />
      <path
        fill="#FCE410"
        d="M173.298 47.3745C177.474 47.3745 178.17 48.7664 178.17 52.7102C178.17 55.4941 178.402 57.35 178.634 58.046H187.681V57.118C186.521 57.118 186.753 55.7261 186.753 51.7823C186.753 45.5186 184.897 44.8226 182.114 43.6627C185.361 42.7347 186.753 39.7188 186.753 36.007C186.753 29.9753 183.738 27.1914 174.69 27.1914H161.931V58.278H170.746V47.3745H173.298ZM170.746 34.3831H174.922C177.01 34.3831 178.402 35.311 178.402 37.6309C178.402 39.7188 177.01 40.6468 174.226 40.6468H170.746V34.3831Z"
      />
      <path
        fill="#FCE410"
        d="M197.919 58.1829L198.847 54.0071H207.663L208.823 58.1829H217.638L208.127 27.0963H199.079L189.335 58.1829H197.919ZM206.271 47.7434H200.703L203.487 36.6079L206.271 47.7434Z"
      />
      <path
        fill="#FCE410"
        d="M220.461 58.1829V27.0963H231.133L236.005 46.3514L240.876 27.0963H251.548V58.1829H243.428V48.6713V43.7995L243.892 38.2318L239.021 58.1829H232.757L227.885 38.2318L228.349 42.8716L228.581 47.7434V58.1829H220.461Z"
      />
      <path
        fill="#FCE410"
        d="M263.05 58.1829L263.978 54.0071H272.793L273.953 58.1829H282.769L273.257 27.0963H264.21L254.466 58.1829H263.05ZM271.401 47.7434H265.834L268.618 36.6079L271.401 47.7434Z"
      />
      <path
        fill="#FCE410"
        d="M334.041 83.2841L314.09 70.2927L304.114 48.4857L307.594 24.8228L323.137 6.7277L346.104 2.28882e-05L369.071 6.7277L384.614 24.8228L388.094 48.4857L378.119 70.2927L358.168 83.2841H334.041Z"
      />
      <path
        fill="#018150"
        d="M335.157 79.3936L317.062 67.5621L308.014 47.843L311.03 26.2681L325.181 9.79686L345.828 3.76514L366.475 9.79686L380.627 26.2681L383.642 47.843L374.595 67.5621L356.5 79.3936H335.157Z"
      />
      <path
        fill="#FCE410"
        d="M339.328 14.5921H354.407V64.0057H358.583V68.6455H333.992V64.0057H337.704V30.5993H333.528L339.328 14.5921Z"
      />
      <path
        fill="#FCE410"
        d="M323.945 35.2089C320.697 35.6729 317.681 37.0648 317.681 41.4726C317.681 45.6484 321.161 46.5764 323.945 47.5043V51.9121C322.553 51.6802 321.857 50.5202 321.857 48.8963H317.217C317.217 53.3041 320.233 55.392 323.945 55.624V58.4078H326.033V55.624C329.744 55.392 332.76 54 332.76 49.3603C332.76 45.1845 329.048 44.0245 326.033 42.8646V38.9208C327.425 38.9208 328.121 40.0807 328.121 41.2407H332.528C332.528 36.8329 329.28 35.4409 326.033 35.2089V33.353H323.945C323.945 33.585 323.945 35.2089 323.945 35.2089ZM326.265 47.9683C327.425 48.2003 328.584 48.8963 328.584 50.2882C328.584 51.4482 327.425 52.1441 326.265 52.1441V47.9683ZM323.945 42.6326C323.017 42.1686 322.089 41.9366 322.089 40.7767C322.089 39.6167 323.249 38.9208 323.945 39.1527V42.6326Z"
      />
      <path
        fill="#FCE410"
        d="M354.289 60.4517C354.289 60.2197 354.521 59.7557 354.289 59.5237C354.289 59.2918 354.057 59.2918 354.057 59.2918C353.825 59.2918 353.129 60.2197 352.897 60.4517C352.433 61.1477 352.201 61.8436 351.969 62.7716C352.433 62.5396 352.897 62.3076 353.361 62.3076C353.825 62.3076 354.289 60.4517 354.289 60.4517ZM351.273 68.5713C351.273 68.8033 351.041 69.4993 350.809 69.4993C350.345 69.7313 350.113 69.2673 350.113 69.0353C349.881 68.1073 349.881 67.1794 350.113 66.0194C350.577 64.6275 350.809 63.0036 351.273 60.6837L349.417 64.1635L348.953 63.4675L351.505 56.9719C351.737 56.2759 351.969 55.8119 352.433 55.5799C352.665 55.5799 353.361 55.3479 353.361 55.5799C353.593 56.0439 352.897 57.2038 352.665 57.4358C352.433 58.3638 351.969 59.0598 351.969 59.9877C352.433 59.2917 353.825 57.4358 354.753 56.9719C355.217 56.7399 355.681 56.9719 355.681 57.4358C356.145 58.3638 355.681 59.7557 354.289 62.3076C355.681 61.8436 355.913 61.3797 357.305 59.2918L357.769 59.9877C357.073 61.6116 355.913 63.4676 354.057 64.1635C353.593 64.3955 353.129 64.3955 352.665 64.3955C352.433 64.6275 352.201 64.6275 351.969 64.8595C351.737 64.8595 351.505 65.0915 351.273 65.0915V68.5713Z"
      />
      <path
        fill="#FCE410"
        d="M358.908 56.828C359.604 55.204 360.068 53.1161 360.068 52.1882C359.372 52.8842 359.14 54.7401 358.908 56.828ZM363.316 57.7559C362.852 59.1479 361.924 61.4677 360.3 61.9317C359.14 62.3957 358.212 61.2358 357.98 60.3078C357.284 58.4519 357.284 56.364 357.98 54.5081C358.676 52.1882 359.836 49.8683 360.3 49.6363C360.532 49.6363 360.764 49.8683 360.764 50.1003C361.228 51.7242 361.46 53.3481 359.14 58.4519C359.14 58.9159 359.14 59.3798 359.372 59.6118C359.604 59.8438 360.068 60.0758 360.532 60.0758C361.46 59.6118 362.388 58.2199 362.852 57.2919L363.316 57.7559Z"
      />
      <path
        fill="#FCE410"
        d="M367.03 54.0211C367.262 53.5571 368.19 51.2372 368.654 51.2372C368.886 51.2372 368.886 51.2372 369.35 51.9331C369.582 52.3971 369.118 52.8611 369.118 53.3251C368.654 54.021 368.19 55.877 368.422 56.5729C368.422 56.8049 368.654 56.8049 368.886 56.8049C369.582 56.5729 370.51 54.717 370.974 54.253L371.437 54.949C370.974 56.109 369.814 58.6608 368.654 59.1248C367.958 59.3568 367.494 58.8928 367.262 58.4288C367.03 57.9649 367.03 57.2689 367.03 56.8049C366.566 57.7329 366.102 59.5888 365.174 60.0528C364.246 60.2848 363.55 59.5888 363.318 58.8928C362.622 57.2689 363.318 54.949 364.014 54.0211C364.246 53.7891 364.478 53.5571 364.71 53.5571C364.942 53.5571 365.638 53.0931 365.87 53.5571C365.87 53.7891 365.638 54.485 365.638 54.717C365.406 55.413 365.406 55.645 364.71 56.3409C364.246 56.8049 364.014 57.5009 364.246 58.1968C364.246 58.1968 364.246 58.4288 364.478 58.4288C364.71 58.1969 365.638 57.5009 366.102 56.3409L367.03 54.0211Z"
      />
      <path
        fill="#FCE410"
        d="M371.203 54.6566C371.899 54.4246 372.363 54.8885 372.827 55.1205C373.523 54.1926 373.755 53.7286 373.523 52.8006C373.291 52.5687 373.059 52.1047 372.827 51.4087L371.203 54.6566ZM377.003 52.1047L377.467 52.5687C376.771 53.9606 375.611 56.2805 374.219 56.7445C373.059 57.2084 371.667 56.9764 370.739 55.8165C370.739 56.0485 370.507 56.0485 370.507 56.2805C370.275 56.2805 370.043 56.2805 370.043 56.0485C370.043 55.8165 370.043 55.5845 370.275 55.3525L372.595 50.4808C372.363 50.0168 372.595 49.3208 373.059 49.0888C373.523 48.8568 373.755 49.0888 373.987 49.5528C374.219 50.2488 374.683 50.7127 375.147 51.4087C375.611 52.8006 374.915 54.1926 374.219 55.3525C375.379 55.1205 375.843 54.4246 376.539 53.4966L377.003 52.1047Z"
      />
      <path
        stroke="#278355"
        stroke-width="3.36918"
        stroke-miterlimit="9.2796"
        d="M354.289 60.4517C354.289 60.2197 354.521 59.7557 354.289 59.5238C354.289 59.2918 354.057 59.2918 354.057 59.2918C353.825 59.2918 353.129 60.2197 352.897 60.4517C352.433 61.1477 352.201 61.8437 351.969 62.7716C352.433 62.5396 352.897 62.3076 353.361 62.3076C353.825 62.3076 354.289 60.4517 354.289 60.4517Z"
      />
      <path
        stroke="#278355"
        stroke-width="3.36918"
        stroke-miterlimit="9.2796"
        d="M351.22 68.5157C351.22 68.7476 350.988 69.4436 350.756 69.4436C350.292 69.6756 350.06 69.2116 350.06 68.9796C349.828 68.0517 349.828 67.1237 350.06 65.9638C350.524 64.5718 350.756 62.9479 351.22 60.628L349.596 64.1079L349.132 63.4119L351.684 56.9162C351.916 56.2202 352.148 55.7563 352.612 55.5243C352.844 55.5243 353.54 55.2923 353.54 55.5243C353.772 55.9883 353.076 57.1482 352.844 57.3802C352.612 58.3081 352.148 59.0041 352.148 59.932C352.612 59.2361 354.004 57.3802 354.931 56.9162C355.395 56.6842 355.859 56.9162 355.859 57.3802C356.323 58.3081 355.859 59.7001 354.468 62.2519C355.859 61.788 356.091 61.324 357.483 59.2361L357.947 59.932C357.251 61.556 356.091 63.4119 354.236 64.1079C353.772 64.3398 353.308 64.3398 352.844 64.3398C352.612 64.5718 352.38 64.5718 352.148 64.8038C351.916 64.8038 351.684 65.0358 351.452 65.0358C351.684 64.8038 351.22 68.5157 351.22 68.5157Z"
      />
      <path
        stroke="#278355"
        stroke-width="3.36918"
        stroke-miterlimit="9.2796"
        d="M358.908 56.8303C359.604 55.2064 360.068 53.1185 360.068 52.1905C359.372 52.8865 359.14 54.7424 358.908 56.8303Z"
      />
      <path
        stroke="#278355"
        stroke-width="3.36918"
        stroke-miterlimit="9.2796"
        d="M363.288 57.7537C362.824 59.1457 361.896 61.4655 360.272 61.9295C359.112 62.3935 358.416 61.2336 357.952 60.3056C357.256 58.4497 357.256 56.3618 357.952 54.5059C358.648 52.186 359.808 49.8661 360.272 49.6341C360.504 49.6341 360.736 49.8661 360.736 50.0981C361.2 51.722 361.432 53.3459 359.112 58.4497C359.112 58.6817 359.112 59.3776 359.344 59.6096C359.576 59.8416 360.04 60.0736 360.504 60.0736C361.432 59.6096 362.36 58.2177 362.824 57.2897C363.056 57.2897 363.288 57.7537 363.288 57.7537Z"
      />
      <path
        stroke="#278355"
        stroke-width="3.36918"
        stroke-miterlimit="9.2796"
        d="M367.027 54.0234C367.259 53.5594 368.187 51.2395 368.651 51.2395C368.883 51.2395 368.883 51.2395 369.347 51.9355C369.579 52.3994 369.115 52.8634 369.115 53.3274C368.651 54.0234 368.187 55.8793 368.419 56.5752C368.419 56.8072 368.651 56.8072 368.883 56.8072C369.579 56.5752 370.507 54.7193 370.971 54.2554L371.435 54.9513C370.971 56.1113 369.811 58.6631 368.651 59.1271C367.955 59.3591 367.491 58.8951 367.259 58.4312C367.027 57.9672 367.027 57.2712 367.027 56.8072C366.563 57.7352 366.099 59.5911 365.171 60.0551C364.244 60.2871 363.548 59.5911 363.316 58.8951C362.62 57.2712 363.316 54.9513 364.012 54.0234C364.244 53.7914 364.476 53.5594 364.707 53.5594C364.939 53.5594 365.635 53.0954 365.867 53.5594C365.867 53.7914 365.635 54.4873 365.635 54.7193C365.403 55.4153 365.403 55.6473 364.707 56.3433C364.244 56.8072 364.012 57.5032 364.244 58.1992C364.244 58.1992 364.244 58.4312 364.476 58.4312C364.707 58.1992 365.635 57.5032 366.099 56.3433C366.099 56.1113 367.027 54.0234 367.027 54.0234Z"
      />
      <path
        stroke="#278355"
        stroke-width="3.36918"
        stroke-miterlimit="9.2796"
        d="M371.201 54.6566C371.897 54.4246 372.361 54.8886 372.825 55.1206C373.521 54.1926 373.753 53.7287 373.521 52.8007C373.289 52.5687 373.057 52.1048 372.825 51.4088C372.825 51.4088 371.201 54.6566 371.201 54.6566Z"
      />
      <path
        stroke="#278355"
        stroke-width="3.36918"
        stroke-miterlimit="9.2796"
        d="M376.827 52.0886L377.291 52.5525C376.595 53.9445 375.435 56.2644 374.043 56.7284C372.883 57.1923 371.491 56.9603 370.563 55.8004C370.563 56.0324 370.331 56.0324 370.331 56.2644C370.099 56.2644 369.867 56.2644 369.867 56.0324C369.867 55.8004 369.867 55.5684 370.099 55.3364L372.419 50.4646C372.187 50.0007 372.419 49.3047 372.883 49.0727C373.347 48.8407 373.579 49.0727 373.811 49.5367C374.043 50.2327 374.507 50.6966 374.971 51.3926C375.435 52.7845 374.739 54.1765 374.043 55.3364C375.203 55.1044 375.667 54.4085 376.363 53.4805C376.131 53.2485 376.827 52.0886 376.827 52.0886Z"
      />
      <path
        fill="#FCE410"
        d="M354.289 60.4517C354.289 60.2197 354.521 59.7557 354.289 59.5237C354.289 59.2918 354.057 59.2918 354.057 59.2918C353.825 59.2918 353.129 60.2197 352.897 60.4517C352.433 61.1477 352.201 61.8436 351.969 62.7716C352.433 62.5396 352.897 62.3076 353.361 62.3076C353.825 62.3076 354.289 60.4517 354.289 60.4517ZM351.273 68.5713C351.273 68.8033 351.041 69.4993 350.809 69.4993C350.345 69.7313 350.113 69.2673 350.113 69.0353C349.881 68.1073 349.881 67.1794 350.113 66.0194C350.577 64.6275 350.809 63.0036 351.273 60.6837L349.417 64.1635L348.953 63.4675L351.505 56.9719C351.737 56.2759 351.969 55.8119 352.433 55.5799C352.665 55.5799 353.361 55.3479 353.361 55.5799C353.593 56.0439 352.897 57.2038 352.665 57.4358C352.433 58.3638 351.969 59.0598 351.969 59.9877C352.433 59.2917 353.825 57.4358 354.753 56.9719C355.217 56.7399 355.681 56.9719 355.681 57.4358C356.145 58.3638 355.681 59.7557 354.289 62.3076C355.681 61.8436 355.913 61.3797 357.305 59.2918L357.769 59.9877C357.073 61.6116 355.913 63.4676 354.057 64.1635C353.593 64.3955 353.129 64.3955 352.665 64.3955C352.433 64.6275 352.201 64.6275 351.969 64.8595C351.737 64.8595 351.505 65.0915 351.273 65.0915V68.5713Z"
      />
      <path
        fill="#FCE410"
        d="M358.908 56.828C359.604 55.204 360.068 53.1161 360.068 52.1882C359.372 52.8842 359.14 54.7401 358.908 56.828ZM363.316 57.7559C362.852 59.1479 361.924 61.4677 360.3 61.9317C359.14 62.3957 358.212 61.2358 357.98 60.3078C357.284 58.4519 357.284 56.364 357.98 54.5081C358.676 52.1882 359.836 49.8683 360.3 49.6363C360.532 49.6363 360.764 49.8683 360.764 50.1003C361.228 51.7242 361.46 53.3481 359.14 58.4519C359.14 58.9159 359.14 59.3798 359.372 59.6118C359.604 59.8438 360.068 60.0758 360.532 60.0758C361.46 59.6118 362.388 58.2199 362.852 57.2919L363.316 57.7559Z"
      />
      <path
        fill="#FCE410"
        d="M367.03 54.0211C367.262 53.5571 368.19 51.2372 368.654 51.2372C368.886 51.2372 368.886 51.2372 369.35 51.9331C369.582 52.3971 369.118 52.8611 369.118 53.3251C368.654 54.021 368.19 55.877 368.422 56.5729C368.422 56.8049 368.654 56.8049 368.886 56.8049C369.582 56.5729 370.51 54.717 370.974 54.253L371.437 54.949C370.974 56.109 369.814 58.6608 368.654 59.1248C367.958 59.3568 367.494 58.8928 367.262 58.4288C367.03 57.9649 367.03 57.2689 367.03 56.8049C366.566 57.7329 366.102 59.5888 365.174 60.0528C364.246 60.2848 363.55 59.5888 363.318 58.8928C362.622 57.2689 363.318 54.949 364.014 54.0211C364.246 53.7891 364.478 53.5571 364.71 53.5571C364.942 53.5571 365.638 53.0931 365.87 53.5571C365.87 53.7891 365.638 54.485 365.638 54.717C365.406 55.413 365.406 55.645 364.71 56.3409C364.246 56.8049 364.014 57.5009 364.246 58.1968C364.246 58.1968 364.246 58.4288 364.478 58.4288C364.71 58.1969 365.638 57.5009 366.102 56.3409L367.03 54.0211Z"
      />
      <path
        fill="#FCE410"
        d="M371.203 54.6566C371.899 54.4246 372.363 54.8885 372.827 55.1205C373.523 54.1926 373.755 53.7286 373.523 52.8006C373.291 52.5687 373.059 52.1047 372.827 51.4087L371.203 54.6566ZM377.003 52.1047L377.467 52.5687C376.771 53.9606 375.611 56.2805 374.219 56.7445C373.059 57.2084 371.667 56.9764 370.739 55.8165C370.739 56.0485 370.507 56.0485 370.507 56.2805C370.275 56.2805 370.043 56.2805 370.043 56.0485C370.043 55.8165 370.043 55.5845 370.275 55.3525L372.595 50.4808C372.363 50.0168 372.595 49.3208 373.059 49.0888C373.523 48.8568 373.755 49.0888 373.987 49.5528C374.219 50.2488 374.683 50.7127 375.147 51.4087C375.611 52.8006 374.915 54.1926 374.219 55.3525C375.379 55.1205 375.843 54.4246 376.539 53.4966L377.003 52.1047Z"
      />
    </Icon>
  )
}
