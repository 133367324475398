import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
  color?: string | undefined
}


export const Quotes: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 19 13" { ...props }>
      <path
        fill="currentColor"
        d="M0.584 12.208L5.48 0.399998H9.656L7.064 12.208H0.584ZM9.848 12.208L14.744 0.399998H18.92L16.28 12.208H9.848Z"
      />
    </Icon>
  )
}
