import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
  color?: string | undefined
}


export const Checkmark: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 20 20" { ...props }>
      <circle
        stroke="currentColor"
        cx="9.66667"
        cy="9.66667"
        r="9.16667"
        fill="none"
      />
      <path
        fill="currentColor"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.0298 11.4304L4.35001 9.75487L8.19235 13.6651L15.4667 5.80005L8.0298 11.4304Z"
      />
    </Icon>
  )
}
