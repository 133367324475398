import * as React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  VStack
} from "@chakra-ui/react";
import { keyframes } from "@chakra-ui/react";

import {
  Benefit,
  Client
} from "./Templates";


interface Props {
  nodes: any[]
  template: string
  itemWidth: number

  heading?: React.ReactNode | string | undefined
  description?: React.ReactNode | string | undefined
}


const ticker = ( scrollLength: number ) => keyframes`
  0% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(-${ scrollLength }px, 0, 0);
  }
`;


export const Ticker: React.FC<Props> = ( props ) => {
  const tickerAnimation = `${ ticker( props.itemWidth * props.nodes.length ) } 60s linear infinite`;

  const nodeList = props.nodes.map( ( node: any, _index: number ) => {
    switch ( props.template ) {
      case 'benefit':
        return (
          <Benefit
            key={ _index }
            node={ node }
          />
        )
      case 'client':
        return (
          <Client
            key={ _index }
            node={ node.node }
          />
        )
      default:
        return null;
    }
  })

  return (
    <VStack
      spacing="32px"
    >
      { props.heading &&
        <VStack
          spacing="24px"
          textAlign="center"
        >
          <Heading
            as="h2"
            color="text.primary"
            fontSize="36px"
            fontWeight={ 700 }
          >
            { props.heading }
          </Heading>
          <Text
            color="text.primary"
            fontSize="16px"
            fontWeight={ 400 }
          >
            { props.description }
          </Text>
        </VStack>
      }
      <Box
        w="100%"
        overflow="hidden"
        boxSizing="content-box"
      >
        <Flex
          boxSizing="content-box"
          animation={ tickerAnimation }
        >
          { nodeList }
          { nodeList }
        </Flex>
      </Box>
    </VStack>
  )
}
