import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
  color?: string | undefined
}


export const Clock: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 20 20" { ...props }>
      <path
        fill="currentColor"
        d="M10 0C4.48421 0 0 4.48421 0 10C0 15.5158 4.48421 20 10 20C15.5158 20 20 15.5158 20 10C20 4.48421 15.5158 0 10 0ZM14.9474 10.6316H12.1262C11.8525 11.5579 11.0104 12.2315 9.99992 12.2315C8.77887 12.2315 7.76838 11.2421 7.76838 10C7.76838 8.98951 8.44214 8.14733 9.36835 7.87372V4.18951C9.36835 3.85264 9.64207 3.55789 9.99996 3.55789C10.3578 3.55789 10.6316 3.83162 10.6316 4.18951V7.87372C11.3473 8.08431 11.9158 8.65263 12.1474 9.38951H14.9684C15.3053 9.38951 15.6 9.66324 15.6 10.0211C15.6 10.3789 15.2841 10.6316 14.9474 10.6316H14.9474Z"
      />
      <path
        fill="currentColor"
        d="M10.9894 9.99973C10.9894 10.5462 10.5464 10.9892 9.99997 10.9892C9.45354 10.9892 9.0105 10.5462 9.0105 9.99973C9.0105 9.4533 9.45354 9.01025 9.99997 9.01025C10.5464 9.01025 10.9894 9.4533 10.9894 9.99973Z"
      />
    </Icon>
  )
}
