import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Link,
  VStack
} from "@chakra-ui/react";

import {
  SiteNav
} from "../Navigation";
import {
  DestinyIcon,
  DestinyLogo,
  Phone
} from "../ScalableVectorGraphics";

import { Burger } from "./Burger";


export const Header: React.FC = () => {
  return (
    <Box
      as="header"
      position="fixed"
      w="100%"
      bg="background.azureishwhite"
      zIndex={ 1000 }
    >
      <Container
        maxW={{
          base: '100%',
          sm: '640px',
          md: '768px',
          lg: '1024px',
          xl: '1280px'
        }}
        w="100%"
        padding="0 1rem"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          h={{
            base: '50px',
            lg: '62px'
          }}
          m={{
            base: '15px 0',
            lg: '26px 0'
          }}
        >
          <Link
            as={ GatsbyLink }
            to="/"
            display={{
              base: 'none',
              lg: 'inline-block'
            }}
            _hover={{
              opacity: '0.6'
            }}
          >
            <DestinyLogo
              w="auto"
              h="62px"
            />
          </Link>
          <Link
            as={ GatsbyLink }
            to="/"
            display={{
              base: 'inline-block',
              lg: 'none'
            }}
            _hover={{
              opacity: '0.6'
            }}
          >
            <DestinyIcon
              w="auto"
              h="50px"
            />
          </Link>
          <Box
            display={{
              base: 'none',
              lg: 'block'
            }}
          >
            <SiteNav
              navList={[
                {
                  label: 'Our Story',
                  path: '#about'
                },
                {
                  label: 'Services',
                  path: '#services'
                },
                {
                  label: 'Projects',
                  path: '#projects'
                },
                {
                  label: 'Contact Us',
                  path: '#contact'
                }
              ]}
            />
          </Box>
          <HStack
            justify="center"
            align="center"
            display={{
              base: 'none',
              lg: 'flex'
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              w="40px"
              h="40px"
              bg="primary.900"
              borderRadius="full"
            >
              <Phone
                w="16px"
                h="16px"
                color="white"
              />
            </Flex>
            <VStack
              align="flex-end"
              spacing="0"
              pt="20px"
            >
              <Button
                as={ Link }
                href="tel:+19059700888"
                variant="link"
                color="primary.900"
                fontSize="19px"
                fontWeight={ 700 }
                _hover={{
                  opacity: '0.6',
                  textDecoration: 'none'
                }}
              >
                905-970-0888
              </Button>
              <Link
                href="#contact"
                color="text.primary"
                fontWeight={ 500 }
                textDecoration="underline"
                _hover={{
                  opacity: '0.6'
                }}
              >
                Request a Call
              </Link>
            </VStack>
          </HStack>
          <Box
            display={{
              lg: 'none'
            }}
          >
            <Burger />
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}
