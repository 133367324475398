import * as React from "react";
import {
  Flex,
  Heading,
  VStack
} from "@chakra-ui/react";

import { ScalableVectorGraphicsFactory } from "../../ScalableVectorGraphics";


interface Props {
  node: any
}


export const Benefit: React.FC<Props> = ( props ) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      minW="250px"
      w="250px"
    >
      <VStack
        justify="flex-start"
        align="center"
        spacing="24px"
      >
        <ScalableVectorGraphicsFactory
          svg={ props.node.svg }
          w="auto"
          h="60px"
          color="primary.900"
        />
        <Heading
          as="h3"
          w="83px"
          color="primary.900"
          fontSize="14px"
          fontWeight={ 700 }
          textAlign="center"
        >
          { props.node.heading }
        </Heading>
      </VStack>
    </Flex>
  )
}
