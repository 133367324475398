/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import {
  Box
} from "@chakra-ui/react";

import { Header } from "../Header";
import { Footer } from "../Footer";


interface Props {
  children: React.ReactNode
}


export const Layout: React.FC<Props> = ({
  children
}) => {
  return (
    <>
      <Header />
      
      <Box
        as="main"
        pt={{
          base: '80px',
          lg: '114px'
        }}
      >
        { children }
      </Box>

      <Footer />
    </>
  )
}
