import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
  color?: string | undefined
}


export const ArrowLeft: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 11 17" { ...props }>
      <path
        fill="currentColor"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.64303 8.08021L10.659 1.31091L9.56305 0L-1.62125e-05 8.08032L9.56305 16.1606L10.659 14.8497L2.64303 8.08021Z"
      />
    </Icon>
  )
}
