import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
  color?: string | undefined
}


export const Certificate: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 62 62" { ...props }>
      <path
        fill="currentColor"
        d="M39.6068 43.9435C39.8235 43.8352 39.9317 43.6727 39.9317 43.456V33.4905H40.7442C41.0691 33.4905 41.2859 33.2738 41.2859 32.9488V30.8365L42.7482 29.3198C42.9649 29.1031 42.9649 28.7782 42.7482 28.5616L41.2859 27.0993V24.9869C41.2859 24.662 41.0691 24.4452 40.7442 24.4452H38.6318L37.2777 23.0911V18.5417C37.2777 18.2167 37.061 18 36.736 18H26.9868C26.8243 18 26.7161 18.0542 26.6076 18.1625L20.1625 24.6076C20.0542 24.7159 20 24.8243 20 24.9868V40.8024C20 41.1273 20.2167 41.3441 20.5417 41.3441H33.5949L33.5948 43.4562C33.5948 43.6729 33.703 43.8894 33.9197 43.9437C34.1364 44.0519 34.353 43.9979 34.5155 43.8354L36.7903 41.8313L39.0651 43.8354C39.2818 44.0521 39.5525 43.9979 39.6068 43.9437L39.6068 43.9435ZM26.4997 19.8416V24.4454L21.8958 24.4452L26.4997 19.8416ZM33.5948 40.2606H21.1376V25.5286H27.0414C27.3664 25.5286 27.5831 25.3119 27.5831 24.9869L27.5829 19.0831H36.249V23.091L34.8949 24.4452H32.7826C32.4576 24.4452 32.2409 24.6619 32.2409 24.9868V27.0992L30.7243 28.5615C30.6161 28.6698 30.3993 28.9407 30.7243 29.3198L32.2409 30.8364V32.9487C32.2409 33.2737 32.4576 33.4904 32.7826 33.4904H33.595L33.5948 40.2606ZM33.324 32.4069V30.6196C33.324 30.4571 33.2698 30.3488 33.1615 30.2404L31.9158 28.9947L33.1615 27.749C33.2698 27.6408 33.324 27.5323 33.324 27.3698V25.5825H35.1114C35.2738 25.5825 35.3821 25.5282 35.4906 25.42L36.7362 24.1743L37.9819 25.42C38.0902 25.5282 38.1986 25.5825 38.3611 25.5825H40.1484V27.3698C40.1484 27.5323 40.2027 27.6406 40.3109 27.749L41.5566 28.9947L40.3109 30.2404C40.2027 30.3486 40.1484 30.4571 40.1484 30.6196V32.4069H38.3611C38.1986 32.4069 38.0903 32.4611 37.9819 32.5694L36.7362 33.8151L35.4906 32.5694C35.3823 32.4611 35.2738 32.4069 35.1114 32.4069H33.324ZM38.8486 42.2647L37.1152 40.7481C36.8985 40.5857 36.5736 40.5857 36.4111 40.7481L34.6779 42.2647V33.4905H34.8946L36.357 34.9528C36.682 35.2778 37.007 35.0611 37.1152 34.9528L38.6318 33.4905H38.8486V42.2647Z"
      />
      <circle
        stroke="currentColor"
        fill="none"
        cx="31"
        cy="31"
        r="30"
      />
    </Icon>
  )
}
