import * as React from "react";

import {
  ArrowLeft,
  ArrowRight,
  Certificate,
  Expert,
  Free,
  License,
  Shield
} from "./Icons";
import {
  BurgerKing,
  Dollarama,
  Hilton,
  Magna,
  Mcdonalds,
  NovaSteel,
  Ontario,
  PetroCanada,
  Toyota,
  Uhaul,
  Wendys
} from "./Logos";


interface Props {
  svg: string
  w?: string
  h?: string
  color?: string
  transition?: string
  _hover?: object
}


export const ScalableVectorGraphicsFactory: React.FC<Props> = ( props ) => {
  switch ( props.svg ) {
    case 'arrow-left':
      return <ArrowLeft { ...props } />;
    case 'arrow-right':
      return <ArrowRight { ...props } />;
    case 'certificate':
      return <Certificate { ...props } />;
    case 'expert':
      return <Expert { ...props } />;
    case 'free':
      return <Free { ...props } />;
    case 'license':
      return <License { ...props } />;
    case 'shield':
      return <Shield { ...props } />;
    case 'burger-king':
      return <BurgerKing { ...props } />;
    case 'dollarama':
      return <Dollarama { ...props } />;
    case 'hilton':
      return <Hilton { ...props } />;
    case 'magna':
      return <Magna { ...props } />;
    case 'mcdonalds':
      return <Mcdonalds { ...props } />;
    case 'novasteel':
      return <NovaSteel { ...props } />;
    case 'ontario':
      return <Ontario { ...props } />;
    case 'petro-canada':
      return <PetroCanada { ...props } />;
    case 'toyota':
      return <Toyota { ...props } />;
    case 'uhaul':
      return <Uhaul { ...props } />;
    case 'wendys':
      return <Wendys { ...props } />;
    default:
      return null;
  }
}
