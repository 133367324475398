import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
}


export const PetroCanada: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 111 83" { ...props }>
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M106.223 77.8458L105.809 74.5282C105.69 73.6988 105.572 72.7509 105.512 71.8622H105.453C105.394 72.6916 105.216 73.6395 105.098 74.5282L104.565 77.8458H105.512V80.8672H104.091L103.735 82.7038H99.7658L103.202 68.4854H107.882L110.904 82.7038H106.934L106.638 80.8672H105.512V77.7866L106.223 77.8458Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.96933 71.566H4.26554C5.21344 71.566 5.68736 72.3954 5.68736 73.284C5.68736 74.3504 5.03571 74.8836 3.96933 74.8836V78.0827C7.28696 78.2012 9.53818 77.0756 9.53818 73.3433C9.53818 69.6109 6.99072 68.5446 3.61384 68.5446H0.177734V82.763H4.02855V71.566H3.96933Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.965 71.8622V82.7038H23.7566V71.8622H26.0078V68.4854H10.5453V82.7038H17.6545V79.3269H14.3961V77.1941H17.3583V73.8173H14.3961V71.8622H19.965Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.7473 71.566C31.8137 71.5068 32.5246 72.04 32.5246 73.2841C32.5246 74.232 31.9914 75.0614 30.9843 75.0614H30.7473L30.6881 76.8387V76.7794L32.8208 82.7038H37.0271L34.1835 76.7794C35.7238 76.187 36.3754 74.7652 36.3754 73.2248C36.3754 69.4925 33.9465 68.4854 30.6288 68.4854H26.8965V82.7038H30.7473V71.566Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.2998 71.803C43.7216 71.803 43.7809 74.7059 43.7809 75.5946C43.7809 76.4832 43.7216 79.3269 42.2998 79.3269C40.8779 79.3269 40.7595 76.4832 40.7595 75.5946C40.7595 74.7059 40.8187 71.803 42.2998 71.803V68.2484C38.3897 68.2484 36.8494 72.1585 36.8494 75.6538C36.8494 79.09 38.3897 83 42.2998 83C46.2099 83 47.691 79.09 47.691 75.6538C47.691 72.1585 46.1506 68.2484 42.2998 68.2484V71.803Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48.2834 77.135V73.6989H49.646V77.135H48.2834Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M74.6466 75.8908V68.4854H78.3197V82.7038H74.6466L71.9807 75.1206V82.7038H68.2484V68.4854H71.9214L74.6466 75.8908Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M85.3104 77.8458L84.8957 74.5282C84.8365 73.6988 84.718 72.7509 84.6588 71.8622H84.5995C84.481 72.6916 84.3626 73.6395 84.2441 74.5282L83.7109 77.8458H84.5995V80.8672H83.1777L82.8814 82.7038H78.9122L82.3483 68.4854H87.0285L90.0499 82.7038H86.0214L85.7844 80.8672H84.5995V77.7866L85.3104 77.8458Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M90.7609 82.7038H94.1377C96.5074 82.7038 100.595 82.0521 100.595 75.4761C100.595 71.2106 98.2255 68.4261 93.8415 68.4261H90.7016L94.6117 71.9808C96.389 71.9808 96.6852 74.1728 96.6852 75.7131C96.6852 77.0165 96.389 79.2677 94.6117 79.2677V72.04L90.7016 68.4854V82.7038H90.7609Z"
      />
      <path
        fill="#ED2129"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M110.844 64.1014L102.076 61.2577C99.8251 60.4283 99.7066 59.0064 100.536 55.9258L104.091 43.6624C104.505 42.359 103.557 42.0036 102.313 42.4183L89.9315 44.551C88.5096 44.788 87.5617 43.8401 87.384 43.0107L85.9622 37.3233C85.6659 36.3162 84.718 36.1977 84.0071 37.2049L74.7059 48.8166C73.0471 50.8901 69.7295 50.8901 70.7959 46.1506L76.0685 21.1499C76.1277 20.202 75.4168 19.9058 74.7652 20.3205L69.1963 23.5789C67.3005 24.586 65.4639 24.7637 64.4568 22.9272L56.6367 7.16845C55.8073 5.74661 55.0371 5.74661 54.2077 7.16845L46.3876 22.9272C45.3804 24.7637 43.5439 24.586 41.7073 23.5789L36.1385 20.3205C35.4275 19.9058 34.7166 20.202 34.7759 21.1499L40.0485 46.1506C41.0557 50.8901 37.7973 50.8901 36.1385 48.8166L26.8372 37.1456C26.1263 36.1385 25.2377 36.257 24.8822 37.2641L23.4604 42.9515C23.2234 43.7809 22.3348 44.7288 20.9129 44.4918L8.4718 42.359C7.22769 41.9443 6.27979 42.2998 6.6945 43.6031L10.2491 55.8665C11.0785 58.9472 10.96 60.369 8.70879 61.1984L0 64.0421V0H110.726V64.1014H110.844Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63.8052 80.8672L64.0421 82.7038H68.0707L65.0493 68.4854H60.369L57.8808 78.9122C57.5254 79.09 57.1699 79.1492 56.7552 79.1492C54.8594 79.1492 54.3262 77.2534 54.3262 75.5946C54.3262 74.0543 54.6817 72.04 56.696 71.803C57.1699 71.7438 57.9993 71.566 58.5917 71.8623V68.4261C58.2363 68.3669 57.2884 68.2484 56.6367 68.2484C54.7409 68.2484 50.4161 69.3148 50.4161 75.7131C50.4161 80.571 53.1413 82.7038 56.2812 82.7038H61.0207L61.3169 80.8672H62.6203V77.7866L61.8501 77.8458L62.3833 74.5282C62.5018 73.6988 62.6203 72.7509 62.7388 71.8623H62.798C62.8573 72.6917 62.9757 73.6396 63.035 74.5282L63.4497 77.8458L62.6203 77.7866V80.8672H63.8052Z"
      />
    </Icon>
  )
}
