import * as React from "react";
import {
  Button,
  Link,
  Stack
} from "@chakra-ui/react";


interface NavListItem {
  label: string
  path: string
}

type NavListType = NavListItem[];

interface Props {
  navList: NavListType
  toggleMenu?: any | undefined
}


export const SiteNav: React.FC<Props> = ( props ) => {
  return (
    <Stack
      as="nav"
      direction={{
        base: 'column',
        lg: 'row'
      }}
      justify={{
        base: 'flex-start',
        lg: 'center'
      }}
      align={{
        base: 'flex-start',
        lg: 'center'
      }}
      spacing={{
        base: '12px',
        lg: '40px'
      }}
    >
      { props.navList.map( ( navItem: NavListItem, _index: number ) => (
        <Button
          as={ Link }
          href={ navItem.path }
          variant="link"
          color="primary.900"
          fontSize={{
            base: '18px',
            lg: '16px'
          }}
          fontWeight={ 400 }
          _hover={{
            opacity: '0.6',
            textDecoration: 'none'
          }}
          onClick={ props.toggleMenu }
        >
          { navItem.label }
        </Button>
      ))}
    </Stack>
  )
}
