import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
}


export const NovaSteel: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 768 151" { ...props }>
      <path d="M500.5 1.40001C490.4 3.40001 482.9 6.50001 476 11.6C461.1 22.5 453.6 41.1 456 60.6C459.5 88.5 481.7 106.4 512.5 106.2C544.7 106 567.4 84.2 567.4 53.5C567.4 32.5 556.7 15.1 538.5 6.60001C526.4 0.800006 512.9 -0.999994 500.5 1.40001ZM523 30.5C531.5 34.8 536 42.8 536 53.5C536 68.8 526.2 79 511.4 79C497.1 79 487 68.5 487 53.5C487 43.3 491.2 35.7 499.3 31C506.3 27 515.7 26.8 523 30.5Z" fill="black"/>
      <path d="M347.2 53.2L347.5 104.5L362.8 104.8L378 105.1V79.5C378 65.5 378.3 54 378.8 54C379.2 54 387.8 65.5 398 79.5L416.4 105H431.2H446V53.5V2.00004H430.5H415L414.8 26.1L414.5 50.2L397 26.4L379.5 2.50004L363.2 2.20004L347 2.00004L347.2 53.2Z" fill="black"/>
      <path d="M562 2.2C562 2.4 570.5 25.4 580.8 53.5L599.7 104.5L619.2 104.8L638.7 105L642.8 93.8C645 87.6 653.4 64.7 661.4 43C669.4 21.3 676 3.2 676 2.8C676 2.4 668.2 2 658.8 2H641.5L630.6 36C624.6 54.7 619.3 69.7 619 69.3C618.2 68.5 617.4 66 604.5 25.7L596.9 2H579.5C569.9 2 562 2.1 562 2.2Z" fill="black"/>
      <path d="M690.5 3.2C683 23.1 653.8 102.8 653.7 103.5C653.6 104.1 659.9 104.6 670.7 104.8L687.8 105L689.7 99.3L691.7 93.5H710.5H729.3L730.9 99L732.6 104.5L749.8 104.8C761.6 105 767 104.7 767 104C767 103.2 735.1 15.6 730.5 3.7C729.9 2.2 727.9 2 710.4 2C695.7 2 690.8 2.3 690.5 3.2ZM719.6 62.7L720.2 66H710.8C705.6 66 701.1 65.8 700.8 65.5C700.5 65.2 701.3 61.4 702.6 57.2C704 53 706.2 45.7 707.7 41L710.3 32.5L714.6 46C717 53.4 719.2 61 719.6 62.7Z" fill="black"/>
      <path d="M57.7999 5C31.3999 9.5 9.1999 30.3 2.2999 57C0.0999023 65.6 0.0999023 81.4 2.2999 90C8.2999 113 26.1999 132.2 48.5999 139.6C56.3999 142.1 58.9999 142.5 69.4999 142.5C79.2999 142.5 82.8999 142.1 88.9999 140.2C108.9 133.9 123.6 121.5 132.2 103.5C148.5 69.5 134.5 28.3 101 11.7C86.9999 4.8 72.3999 2.6 57.7999 5ZM81.9999 50.5C96.3999 57.8 100.4 76.9 90.0999 89.9C85.3999 95.8 77.9999 99.3 70.0999 99.3C66.1999 99.3 61.9999 98.6 59.7999 97.6C42.0999 89.9 38.4999 66.2 53.0999 53.7C61.2999 46.7 72.0999 45.5 81.9999 50.5Z" fill="black"/>
      <path d="M104.9 8.10001C120.9 16.1 132.8 31.7 157.7 77.4C181 120 190.8 132.7 207 141.1C211 143.2 213.1 143.5 227 143.8L242.5 144.2L236.5 140.3C224.2 132.3 213.2 117 191 77C183.2 63 173.4 46.3 169.2 40C159.8 25.6 147 12.6 138.5 8.40001C132.7 5.60001 131.9 5.50001 115.5 5.20001L98.5 4.90001L104.9 8.10001Z" fill="black"/>
      <path d="M145.9 8.10003C149.4 9.80003 155.1 14 158.6 17.2C170.1 28 178 40 197.7 76.3C204.5 88.8 211.9 102.1 214.1 105.8C224.6 123 236.9 136.2 246.7 140.8C252.1 143.3 253.6 143.5 267.5 143.8L282.5 144.1L277.5 141.2C266.4 134.7 253.9 118.3 238 89.5C213.6 45.3 207.5 35.4 197.6 23.8C191 16 181.6 8.90003 174.7 6.60003C171.2 5.40003 166.1 5.00003 154.8 5.00003H139.7L145.9 8.10003Z" fill="black"/>
      <path d="M185.5 7.90003C201.7 16 213.2 31.3 238 77.5C260.7 119.7 271.1 133.5 285.8 140.7C291.2 143.3 292.4 143.5 306.5 143.8L321.5 144.1L316.5 141.1C304.5 134.2 293.4 118.8 271.9 79.5C256.4 51 250.3 40.9 243 31C236.3 22 225.5 11.8 219 8.40003C213.7 5.60003 213 5.50003 196.5 5.20003L179.5 4.90003L185.5 7.90003Z" fill="black"/>
      <path d="M347 135V150H398H449V135V120H398H347V135Z" fill="black"/>
      <path d="M666 135V150H717H768V135V120H717H666V135Z" fill="black"/>
      <path d="M474.4 122C471.4 123.2 469 126.9 469 130.3C469 134.1 472.5 137.1 479.2 139C482.5 140 484.5 141.1 484.5 142C484.5 144.2 478.7 144.4 474.9 142.4C471.8 140.8 471.6 140.8 469.9 143.1C468.9 144.4 468 145.8 468 146.1C468 147.7 475.8 150.3 480.7 150.4C487 150.4 491 147.9 492.4 143.1C493.8 138.3 490.8 135 483.1 132.7C477 130.8 475.2 128.7 478.9 127.5C480 127.2 482.5 127.6 484.4 128.4C487.8 129.8 488 129.8 490 127.2L492.1 124.6L488.8 122.8C485.3 121 477.9 120.5 474.4 122Z" fill="black"/>
      <path d="M506.7 121.7C506.3 122 506 123.6 506 125.1C506 127.7 506.3 127.9 510.3 128.2L514.5 128.5L514.8 139.3L515.1 150H519H523V139V128H527C530.9 128 531 127.9 531 124.5V121H519.2C512.7 121 507 121.3 506.7 121.7Z" fill="black"/>
      <path d="M547 135.5V150H558.5H570V147V144H562.5C555.2 144 555 143.9 555 141.5C555 139.1 555.2 139 561.5 139H568V136V133H561.5C555.2 133 555 132.9 555 130.5C555 128.1 555.2 128 562.5 128H570V124.5V121H558.5H547V135.5Z" fill="black"/>
      <path d="M586.7 121.7C586.3 122 586 128.6 586 136.2V150.1L597.8 149.8L609.5 149.5L609.8 146.7L610.1 144H602.1C594.1 144 594 144 594 141.5C594 139.1 594.2 139 601 139H608V136V133H601C594.2 133 594 132.9 594 130.5C594 128.1 594.2 128 601.5 128H609V124.5V121H598.2C592.2 121 587 121.3 586.7 121.7Z" fill="black"/>
      <path d="M626 135.5V150H636.5H647V146.5V143H640H633V132V121H629.5H626V135.5Z" fill="black"/>
    </Icon>
  )
}
