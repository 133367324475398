import * as React from "react";
import {
  Box,
  Container,
  Link,
  Stack,
  Text
} from "@chakra-ui/react";


export const Copyright: React.FC = () => {
  return (
    <Box
      w="100%"
      bg="primary.900"
    >
      <Container
        maxW={{
          base: '100%',
          sm: '640px',
          md: '768px',
          lg: '1024px',
          xl: '1280px'
        }}
        w="100%"
        padding="0 1rem"
      >
        <Stack
          direction={{
            base: 'column-reverse',
            lg: 'row'
          }}
          justify={{
            base: 'center',
            lg: 'space-between'
          }}
          align="center"
          w="100%"
          p="12px 0"
          textAlign={{
            base: 'center',
            lg: 'left'
          }}
        >
          <Text
            color="white"
            fontSize="12px"
            fontWeight={ 400 }
          >
            &copy; { new Date().getFullYear() } Destiny Roofing. All Rights Reserved.&nbsp;
            <Link
              href="https://explore.agency"
              isExternal
              _hover={{
                opacity: '0.6',
                textDecoration: 'none'
              }}
            >
              Developed by Explore Agency&trade;
            </Link>
          </Text>
        </Stack>
      </Container>
    </Box>
  )
}
