import * as React from "react";
import { useRef } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Link,
  VStack
} from "@chakra-ui/react";
import { motion, useCycle } from "framer-motion";

import { useDimensions } from "../../hooks";

import {
  SiteNav,
  Socials
} from "../Navigation";


const Path = ( props: any ) => {
  return (
    <motion.path
      fill="transparent"
      strokeWidth="2"
      stroke="#082471FF"
      { ...props }
    />
  )
}

const sidebar = {
  open: ( height = 1000 ) => ({
    clipPath: `circle( ${ height * 2 + 200 }px at 100% 0px )`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle( 0px at 100% 0px )",
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

export const Burger: React.FC = () => {
  const [menuOpen, toggleOpen] = useCycle( false, true );
  const containerRef = useRef( null );
  const { height } = useDimensions( containerRef );

  return (
    <motion.div
      initial={ false }
      animate={ menuOpen ? 'open' : 'closed' }
      custom={ height }
      ref={ containerRef }
    >
      <Flex
        direction="column"
        justifyContent="space-between"
        alignItems="flex-end"
        onClick={ () => toggleOpen() }
      >
        <svg
          width="35"
          height="22"
          viewBox="0 0 35 22"
        >
          <Path
            d="M0 1, L35 1"
            variants={{
              closed: { d: "M0 1, L35 1" },
              open: { d: "M1 19.9998L20 1" }
            }}
          />
          <Path
            d="M11 11, L35 11"
            variants={{
              closed: { opacity: 1 },
              open: { opacity: 0 }
            }}
            transition={{ duration: 0.1 }}
          />
          <Path
            d="M0 21, L35 21"
            variants={{
              closed: { d: "M0 21, L35 21" },
              open: { d: "M1.70706 1.29288L20.5 20" }
            }}
          />
        </svg>
      </Flex>
      <Box
        as={ motion.div }
        variants={ sidebar }
        position="fixed"
        top={ 0 }
        left={ 0 }
        w="100%"
        h="100vh"
        background="white"
        p="80px 0"
        zIndex={ -1 }
      >
        <Container
          maxW={{
            base: '100%',
            sm: '640px',
            md: '768px',
            lg: '1024px',
            xl: '1280px',
            '2xl': '1536px'
          }}
          w="100%"
          padding="2rem 1rem"
        >
          <SiteNav
            navList={[
              {
                label: 'Our Story',
                path: '#about'
              },
              {
                label: 'Services',
                path: '#services'
              },
              {
                label: 'Projects',
                path: '#projects'
              },
              {
                label: 'Contact Us',
                path: '#contact'
              }
            ]}
            toggleMenu={ () => toggleOpen() }
          />
          <Flex
            justifyContent="space-between"
            alignItems="center"
            w="100%"
            padding="2rem 0"
          >
            <Socials />
          </Flex>
          <Divider />
          <VStack
            align="flex-start"
            spacing={ 2 }
            padding="2rem 0"
          >
            <Box>
              <Button
                as={ Link }
                href="mailto:info@destinyroofing.ca"
                variant="link"
                color="primary.900"
                fontSize="18px"
                fontWeight={ 400 }
                lineHeight="21px"
                _hover={{
                  opacity: '0.6',
                  textDecoration: 'none'
                }}
                _focus={{
                  boxShadow: 'none !important'
                }}
              >
                info@destinyroofing.ca
              </Button>
            </Box>
            <Box>
              <Button
                as={ Link }
                href="tel:+19059700888"
                variant="link"
                color="primary.900"
                fontSize="18px"
                fontWeight={ 400 }
                lineHeight="21px"
                _hover={{
                  opacity: '0.6',
                  textDecoration: 'none'
                }}
                _focus={{
                  boxShadow: 'none !important'
                }}
              >
                905-970-0888
              </Button>
            </Box>
          </VStack>
        </Container>
      </Box>
    </motion.div>
  )
}
