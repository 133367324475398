import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
}


export const DestinyIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 51 51" { ...props }>
      <path
        fill="#082471"
        d="M25.5 51C22.0356 51 18.6849 50.3185 15.5613 49.0123C12.4944 47.706 9.76838 45.8886 7.43987 43.5601C5.11136 41.2316 3.23719 38.5056 1.98775 35.4388C0.681515 32.3152 0 28.9644 0 25.5C0 22.0356 0.681515 18.6849 1.98775 15.5613C3.29399 12.4944 5.11136 9.76838 7.43987 7.43987C9.76838 5.11136 12.4944 3.23719 15.5613 1.98775C18.6849 0.681515 22.0356 0 25.5 0C28.9644 0 32.3152 0.681515 35.4388 1.98775C38.5056 3.29399 41.2316 5.11136 43.5601 7.43987C45.8886 9.76838 47.7628 12.4944 49.0123 15.5613C50.3185 18.6849 51 22.0356 51 25.5C51 28.9644 50.3185 32.3152 49.0123 35.4388C47.706 38.5056 45.8886 41.2316 43.5601 43.5601C41.2316 45.8886 38.5056 47.7628 35.4388 49.0123C32.2584 50.3185 28.9076 51 25.5 51ZM25.5 1.76058C19.196 1.76058 13.2327 4.20267 8.74611 8.68931C4.25947 13.1759 1.81738 19.0824 1.81738 25.4432C1.81738 31.7472 4.25947 37.7105 8.74611 42.1971C13.2327 46.6837 19.1392 49.1258 25.5 49.1258C31.804 49.1258 37.7673 46.6837 42.2539 42.1971C46.7405 37.7105 49.1826 31.804 49.1826 25.4432C49.1826 19.1392 46.7405 13.1759 42.2539 8.68931C37.7105 4.25947 31.804 1.76058 25.5 1.76058Z"
      />
      <path
        fill="#082471"
        d="M17.3786 42.3675C17.1514 42.4243 16.7539 42.4811 16.1291 42.6514C16.1291 42.8218 16.0723 42.9922 16.0723 43.2762C16.0156 43.5601 16.0156 43.7873 16.0156 43.9009L15.334 42.8218C15.334 42.8218 14.9365 42.8786 14.0846 43.049C14.6525 42.4243 14.8797 42.0835 14.9365 42.0835C14.9365 42.0835 14.7093 41.686 14.3118 40.9476L15.3908 41.402C15.4476 41.402 15.5044 41.4588 15.5044 41.4588C15.6748 41.2884 15.9588 40.9477 16.3563 40.5501C16.2995 41.0612 16.2995 41.4588 16.2427 41.7995C16.2427 41.7995 16.2995 41.7995 16.2995 41.8563C16.5835 41.9699 16.9242 42.1403 17.3786 42.3675Z"
      />
      <path
        fill="#082471"
        d="M22.3195 43.6738C22.1491 43.7874 21.7516 43.9578 21.1836 44.2417C21.1836 44.4121 21.2404 44.5825 21.2404 44.8664C21.2404 45.1504 21.2972 45.3776 21.2972 45.4912L20.4453 44.5825C20.4453 44.5825 20.0478 44.7529 19.2527 45.0936C19.6502 44.3553 19.8774 44.0146 19.8774 43.9578C19.8774 43.9578 19.5934 43.617 19.0255 42.9923L20.1614 43.2194C20.2182 43.2194 20.275 43.2194 20.275 43.2194C20.3885 42.9923 20.6157 42.6515 20.9565 42.1404C21.0133 42.6515 21.0701 43.0491 21.1269 43.3898C21.1269 43.3898 21.1836 43.3898 21.1836 43.4466C21.4676 43.4466 21.8652 43.5034 22.3195 43.6738Z"
      />
      <path
        fill="#082471"
        d="M27.431 43.9009C27.2606 44.0145 26.9199 44.2985 26.4087 44.696C26.4655 44.8096 26.5223 45.0368 26.5791 45.3208C26.6359 45.6047 26.6927 45.7751 26.7495 45.9455L25.7272 45.2072C25.7272 45.2072 25.3865 45.4343 24.6482 45.9455C24.8753 45.1504 25.0457 44.7528 25.0457 44.7528C25.0457 44.7528 24.705 44.4689 24.0234 43.9577H25.2161C25.2729 43.9577 25.3297 43.9577 25.3297 43.9577C25.4433 43.7306 25.5568 43.333 25.784 42.7651C25.9544 43.2194 26.068 43.617 26.1816 43.9577H26.2384C26.5223 43.8441 26.9199 43.8441 27.431 43.9009Z"
      />
      <path
        fill="#082471"
        d="M32.4856 43.0491C32.372 43.2194 32.088 43.5034 31.6337 44.0146C31.6905 44.1281 31.8041 44.3553 31.9176 44.5825C32.0312 44.8097 32.1448 45.0368 32.2016 45.1504L31.009 44.6393C31.009 44.6393 30.725 44.9232 30.1003 45.548C30.1571 44.7528 30.2139 44.2985 30.2139 44.2985C30.2139 44.2985 29.8163 44.1281 29.078 43.7306L30.2139 43.4466C30.2707 43.4466 30.3274 43.4466 30.3274 43.4466C30.3842 43.1627 30.441 42.7651 30.4978 42.1972C30.7818 42.6515 30.9522 42.9923 31.1225 43.2762H31.1793C31.5769 43.2194 31.9744 43.1627 32.4856 43.0491Z"
      />
      <path
        fill="#082471"
        d="M37.2561 41.2316C37.1425 41.402 36.9153 41.7996 36.6314 42.3675C36.7449 42.4811 36.8585 42.6515 37.0289 42.8787C37.1993 43.1058 37.3697 43.2762 37.4265 43.3898L36.177 43.1626C36.177 43.1626 35.9498 43.5034 35.4955 44.2417C35.3819 43.4466 35.3819 42.9922 35.3251 42.9922C35.3251 42.9922 34.9276 42.8786 34.0757 42.6515L35.1548 42.1403C35.2115 42.1403 35.2683 42.0835 35.2683 42.0835C35.2683 41.7996 35.2115 41.402 35.1548 40.8341C35.4955 41.1749 35.7795 41.5156 36.0066 41.7996H36.0634C36.4042 41.5724 36.8017 41.402 37.2561 41.2316Z"
      />
      <path
        fill="#082471"
        d="M14.7661 17.9465L17.7761 19.6503V17.549L12.9487 14.7661V35.2116H14.7661V17.9465Z"
      />
      <path
        fill="#082471"
        d="M26.7495 38.0512V33.1102V29.9866L36.9155 24.137V35.2116H38.7328V20.9566L37.3698 21.7517L26.7495 27.8853V22.7739L21.6382 19.7639V21.8653L24.9322 23.7962V28.9644V38.0512H26.7495Z"
      />
      <path
        fill="#082471"
        d="M30.7817 9.31401V24.3641L32.5991 23.2851V6.13361L31.2361 6.9287L18.7984 14.1414V36.2338H16.8107H11.0746H9.71157H8.06458C8.23495 36.5178 8.46212 36.8586 8.68929 37.1425C8.91646 37.4833 9.14363 37.7672 9.3708 38.0512H10.3931H20.6158H19.3096H20.6158V15.2205L30.7817 9.31401Z"
      />
      <path
        fill="#082471"
        d="M35.7794 36.2338H35.1547H34.0188H32.599V27.8285L30.7816 28.9075V33.1102V38.108H35.0979H35.7226H42.1402C42.3674 37.7672 42.6513 37.4264 42.8785 37.0857C43.0489 36.8017 43.2193 36.5746 43.3896 36.2906H35.7794V36.2338Z"
      />
    </Icon>
  )
}
