import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
}


export const Uhaul: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 177 79" { ...props }>
      <path
        fill="none"
        stroke="black"
        stroke-width="1.1014"
        stroke-miterlimit="2.6131"
        d="M175.72 6.16599C175.72 3.27399 173.456 1 170.575 1H6.14499C3.26399 1 1 3.27299 1 6.16599V47.697C1 50.59 3.26399 53.07 6.14499 53.07H170.575C173.456 53.07 175.72 50.59 175.72 47.697V6.16599Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.67 6.78601V25.175H34.134V6.78601H42.16C42.16 6.78601 42.16 27.035 42.16 27.448C41.954 29.927 39.279 32.613 35.575 32.613H15.819C11.703 32.613 9.43899 29.927 9.23399 27.448C9.23399 27.241 9.23399 6.78601 9.23399 6.78601H17.67Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M57.389 16.291V6.78601H65.208V16.291H74.881V6.78601H82.495V32.613H74.881V22.902H65.208V32.613H57.389V22.902H46.893V16.291H57.389Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M95.255 6.78601H105.339L116.039 32.613H107.809L105.75 27.448H95.049L93.197 32.613H84.965L95.255 6.78601ZM97.312 21.456H103.692L100.399 12.365L97.312 21.456Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M124.477 6.78601V26.002H134.767V6.78601H142.381V27.448C142.175 30.341 139.705 32.613 136.618 32.613C136.618 32.613 122.624 32.613 122.418 32.613C119.332 32.613 117.067 30.341 116.862 27.448V6.78601H124.477Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M148.35 6.78601H156.169V25.795H168.311V32.613H148.35V6.78601Z"
      />
      <path
        fill="#F06D26"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M171.603 39.019H4.49899V47.904C4.49899 48.937 5.52799 49.968 6.55699 49.968H169.545C170.779 49.968 171.603 48.937 171.603 47.904V39.019Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M163.784 13.192H164.607V11.126H165.43L166.87 13.192H167.693L166.047 10.919C166.664 10.919 167.281 10.506 167.281 9.67996C167.281 9.26596 167.281 8.85297 166.87 8.64697C166.458 8.43997 166.047 8.23297 165.224 8.23297H163.784V13.192ZM164.607 8.64597H165.018C165.841 8.64597 166.664 8.85195 166.664 9.67896C166.664 10.299 166.252 10.505 165.43 10.505H164.607V8.64597ZM165.431 6.57996C162.961 6.57996 161.109 8.43898 161.109 10.712C161.109 13.191 162.962 15.051 165.431 15.051C167.695 15.051 169.546 13.192 169.546 10.712C169.546 8.43898 167.694 6.57996 165.431 6.57996ZM165.431 6.99295C167.489 6.99295 169.135 8.64598 169.135 10.712C169.135 12.778 167.489 14.431 165.431 14.431C163.373 14.431 161.726 12.779 161.726 10.712C161.726 8.64598 163.373 6.99295 165.431 6.99295Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.67599 77.656V60.507H1.41299V77.656H3.67599Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.288 75.589C17.053 72.903 14.377 67.944 10.467 60.507H7.381V77.655H9.439C9.439 70.217 9.43899 65.051 9.23299 62.573H9.439C10.674 65.052 13.349 70.218 17.465 77.655H20.346V60.507H18.288C18.288 67.945 18.494 72.903 18.494 75.589H18.288Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.163 60.507H22.609V61.953H27.96V77.656H30.018V61.953H35.163V60.507Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.896 67.945V61.953H48.333V60.507H37.633V77.656H48.539V76.211H39.896V69.392H46.481V67.945H39.896Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M59.652 70.425C62.533 70.011 64.591 68.566 64.591 65.466C64.591 61.747 61.504 60.507 57.388 60.507H51.831V77.655H54.095V70.632H57.388L62.945 77.655H65.826L59.652 70.425ZM54.096 69.185V61.953H57.183C61.299 61.953 62.534 63.3999 62.534 65.4669C62.534 67.7399 60.888 69.186 58.007 69.186H54.096V69.185Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.791 75.589C77.556 72.903 74.881 67.944 70.765 60.507H67.679V77.655H69.737C69.737 70.217 69.737 65.051 69.737 62.573C70.971 65.052 73.647 70.218 77.763 77.655H80.85V60.507H78.792C78.791 67.945 78.791 72.904 78.791 75.589Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M93.608 72.492L95.254 77.656H97.724L91.549 60.508H88.874L82.906 77.656H85.169L86.816 72.492H93.608ZM92.991 70.838H87.434L88.874 66.293C89.286 64.846 89.697 63.4 90.108 61.748H90.314C90.725 63.4 91.137 64.846 91.754 66.293L92.991 70.838Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M108.426 60.507H95.872V61.953H101.223V77.656H103.28V61.953H108.426V60.507Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M112.747 77.656V60.507H110.689V77.656H112.747Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M115.628 68.978C115.628 75.384 119.127 78.07 123.448 78.07C127.975 78.07 131.474 75.384 131.474 68.978C131.474 62.781 127.975 60.093 123.448 60.093C119.127 60.093 115.628 62.781 115.628 68.978ZM123.448 61.541C126.329 61.541 129.211 63.607 129.211 68.979C129.211 74.352 126.329 76.624 123.448 76.624C120.567 76.624 117.891 74.353 117.891 68.979C117.892 63.607 120.567 61.541 123.448 61.541Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M145.263 75.589C144.029 72.903 141.353 67.944 137.443 60.507H134.356V77.655H136.414C136.414 70.217 136.414 65.051 136.208 62.573H136.414C137.649 65.052 140.324 70.218 144.44 77.655H147.321V60.507H145.263C145.263 67.945 145.468 72.903 145.468 75.589H145.263Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M160.08 72.492L161.932 77.656H164.197L158.228 60.508H155.347L149.379 77.656H151.848L153.494 72.492H160.08ZM159.668 70.838H153.906L155.346 66.293C155.757 64.846 156.374 63.4 156.786 61.748C157.403 63.4 157.815 64.846 158.226 66.293L159.668 70.838Z"
      />
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M176.749 76.211H168.518V60.507H166.253V77.656H176.749V76.211Z"
      />
    </Icon>
  )
}
