import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: object | number | string | undefined
  h?: object | number | string | undefined
}


export const DestinyLogo: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 308 63" { ...props }>
      <path
        fill="#082471"
        d="M79.1442 15.7591H73.5658V31.3788H79.1442C83.7464 31.3788 87.0935 28.1014 87.0935 23.5689C87.0935 19.0364 83.7464 15.7591 79.1442 15.7591ZM76.6339 18.6181H79.0745C81.9334 18.6181 83.9556 20.6402 83.9556 23.5689C83.9556 26.4976 81.9334 28.5198 79.0745 28.5198H76.6339V18.6181Z"
      />
      <path
        fill="#082471"
        d="M95.6704 24.8241H101.388V21.9651H95.6704V18.6181H101.946V15.7591H92.6022V31.3788H102.155V28.4501H95.6704V24.8241Z"
      />
      <path
        fill="#082471"
        d="M113.242 21.9651C111.29 21.477 110.105 21.1284 110.105 20.0824C110.105 18.9667 111.36 18.4786 112.545 18.4786C113.731 18.4786 115.055 19.0365 115.055 20.5008V20.7797H118.054V20.5008C118.054 17.5024 115.962 15.6197 112.545 15.6197C109.128 15.6197 106.967 17.3629 106.967 20.2219C106.967 23.569 109.826 24.2662 112.127 24.8241C114.079 25.3122 115.334 25.6609 115.334 26.8463C115.334 28.1014 114.01 28.7987 112.685 28.7987C110.941 28.7987 109.826 27.8922 109.826 26.4976V26.2187H106.758V26.4976C106.758 29.7052 109.128 31.7274 112.754 31.7274C116.241 31.7274 118.542 29.775 118.542 26.8463C118.542 23.2203 115.613 22.523 113.242 21.9651Z"
      />
      <path
        fill="#082471"
        d="M122.307 18.6181H126.7V31.3788H129.769V18.6181H134.162V15.7591H122.307V18.6181Z"
      />
      <path
        fill="#082471"
        d="M142.25 15.7591H139.182V31.3788H142.25V15.7591Z"
      />
      <path
        fill="#082471"
        d="M158.567 25.6608L151.385 15.7591H148.735V31.3788H151.734V21.2678L159.055 31.3788H161.636V15.7591H158.567V25.6608Z"
      />
      <path
        fill="#082471"
        d="M173.629 22.1743L169.794 15.7591H166.447L172.025 25.103V31.3788H175.163V25.103L180.811 15.7591H177.464L173.629 22.1743Z"
      />
      <path
        fill="#082471"
        d="M202.01 20.7797C202.01 17.851 199.778 15.7591 196.64 15.7591H190.504V31.3788H193.572V25.8003H195.943L199.151 31.3788H202.637L199.081 25.3122C200.894 24.4754 202.01 22.8019 202.01 20.7797ZM198.941 20.7797C198.941 22.0349 197.965 22.9414 196.571 22.9414H193.572V18.6878H196.501C197.965 18.6181 198.941 19.4548 198.941 20.7797Z"
      />
      <path
        fill="#082471"
        d="M214.979 15.4802C210.307 15.4802 206.751 18.9667 206.751 23.5689C206.751 28.1712 210.307 31.6577 214.979 31.6577C219.651 31.6577 223.208 28.1712 223.208 23.5689C223.208 18.897 219.651 15.4802 214.979 15.4802ZM220.07 23.5689C220.07 26.4976 217.838 28.729 214.979 28.729C212.051 28.729 209.819 26.4976 209.819 23.5689C209.819 20.6403 212.051 18.4089 214.979 18.4089C217.908 18.4089 220.07 20.6403 220.07 23.5689Z"
      />
      <path
        fill="#082471"
        d="M235.899 15.4802C231.227 15.4802 227.67 18.9667 227.67 23.5689C227.67 28.1712 231.227 31.6577 235.899 31.6577C240.571 31.6577 244.127 28.1712 244.127 23.5689C244.127 18.897 240.64 15.4802 235.899 15.4802ZM241.059 23.5689C241.059 26.4976 238.827 28.729 235.968 28.729C233.04 28.729 230.808 26.4976 230.808 23.5689C230.808 20.6403 233.04 18.4089 235.968 18.4089C238.827 18.4089 241.059 20.6403 241.059 23.5689Z"
      />
      <path
        fill="#082471"
        d="M249.636 31.3788H252.704V25.1727H258.352V22.3138H252.704V18.6181H258.91V15.7591H249.636V31.3788Z"
      />
      <path
        fill="#082471"
        d="M267.277 15.7591H264.209V31.3788H267.277V15.7591Z"
      />
      <path
        fill="#082471"
        d="M283.594 25.6608L276.412 15.7591H273.762V31.3788H276.761V21.1981L284.082 31.3788H286.593V15.7591H283.594V25.6608Z"
      />
      <path
        fill="#082471"
        d="M299.353 23.1506V25.87H304.583C303.956 27.6133 302.212 28.6593 300.051 28.6593C297.122 28.6593 295.17 26.6371 295.17 23.5689C295.17 20.5705 297.192 18.4786 300.051 18.4786C302.212 18.4786 303.956 19.664 304.583 21.477L304.653 21.6862H307.791L307.721 21.3376C307.024 17.9208 303.816 15.5499 300.051 15.5499C295.518 15.5499 292.101 19.0365 292.101 23.6387C292.101 28.3106 295.449 31.7274 300.12 31.7274C304.723 31.7274 307.93 28.3804 308 23.4992V23.2203H299.353V23.1506Z"
      />
      <path
        fill="#082471"
        d="M78.3075 45.9525V47.2076H73.5658V39.2583H78.168V40.5135H74.8209V42.6054H77.8193V43.7908H74.8209V46.0222H78.3075V45.9525Z"
      />
      <path
        fill="#082471"
        d="M85.42 47.2076L83.4675 44.0697L81.5151 47.2076H80.0507L82.6308 43.0238L80.1902 39.2583H81.6545L83.3978 42.0476L85.1411 39.2583H86.6054L84.2346 43.0935L86.8146 47.2076H85.42Z"
      />
      <path
        fill="#082471"
        d="M92.3233 47.3471C89.9525 47.3471 88.2092 45.6038 88.2092 43.233C88.2092 40.8621 89.9525 39.1189 92.3233 39.1189C94.206 39.1189 95.7401 40.3043 96.1585 42.1173H94.8336C94.485 41.0713 93.5087 40.4438 92.3233 40.4438C90.7195 40.4438 89.5341 41.6292 89.5341 43.3027C89.5341 44.9762 90.7195 46.1617 92.3233 46.1617C93.5087 46.1617 94.485 45.4643 94.8336 44.3487H96.1585C95.8098 46.1617 94.2758 47.3471 92.3233 47.3471Z"
      />
      <path
        fill="#082471"
        d="M103.48 45.9525V47.2076H98.7385V39.2583H103.341V40.5135H99.9937V42.6054H102.992V43.7908H99.9937V46.0222H103.48V45.9525Z"
      />
      <path
        fill="#082471"
        d="M110.802 46.0222V47.2076H106.2V39.2583H107.525V46.0222H110.802Z"
      />
      <path
        fill="#082471"
        d="M117.775 46.0222V47.2076H113.173V39.2583H114.498V46.0222H117.775Z"
      />
      <path
        fill="#082471"
        d="M124.957 45.9525V47.2076H120.216V39.2583H124.818V40.5135H121.471V42.6054H124.469V43.7908H121.471V46.0222H124.957V45.9525Z"
      />
      <path
        fill="#082471"
        d="M134.162 39.2583V47.2076H133.046L128.932 41.4897V47.2076H127.677V39.2583H128.862L132.907 44.8368V39.2583H134.162Z"
      />
      <path
        fill="#082471"
        d="M140.926 47.3471C138.555 47.3471 136.811 45.6038 136.811 43.233C136.811 40.8621 138.555 39.1189 140.926 39.1189C142.808 39.1189 144.342 40.3043 144.761 42.1173H143.436C143.087 41.0713 142.111 40.4438 140.926 40.4438C139.322 40.4438 138.136 41.6292 138.136 43.3027C138.136 44.9762 139.322 46.1617 140.926 46.1617C142.111 46.1617 143.087 45.4643 143.436 44.3487H144.761C144.342 46.1617 142.878 47.3471 140.926 47.3471Z"
      />
      <path
        fill="#082471"
        d="M152.082 45.9525V47.2076H147.341V39.2583H151.943V40.5135H148.596V42.6054H151.594V43.7908H148.596V46.0222H152.082V45.9525Z"
      />
      <path
        fill="#082471"
        d="M163.518 47.2076C163.309 46.9984 163.17 46.7892 162.96 46.58C162.333 47.0682 161.566 47.3471 160.659 47.3471C159.195 47.3471 158.219 46.5103 158.219 45.1854C158.219 44.0697 158.916 43.3724 159.753 42.8843C159.125 41.9778 158.916 41.4897 158.916 40.9319C158.916 39.8162 159.753 39.1189 161.008 39.1189C162.263 39.1189 163.1 39.8162 163.1 40.8621C163.1 41.9778 162.193 42.6054 161.287 43.0935C161.705 43.6514 162.263 44.2092 162.891 44.9763C163.1 44.5579 163.239 44.0698 163.239 43.5816H164.285C164.285 44.4881 164.006 45.2552 163.588 45.8827C163.937 46.3011 164.355 46.7892 164.843 47.2774H163.518V47.2076ZM162.193 45.7433C161.426 44.9065 160.799 44.2092 160.38 43.6514C159.823 44 159.404 44.4184 159.404 45.1157C159.404 45.813 159.962 46.2314 160.799 46.2314C161.357 46.2314 161.845 46.0919 162.193 45.7433ZM161.008 40.0951C160.45 40.0951 160.032 40.4438 160.032 40.9319C160.032 41.2805 160.241 41.6989 160.659 42.3265C161.357 41.9081 161.984 41.5594 161.984 40.9319C161.984 40.374 161.636 40.0951 161.008 40.0951Z"
      />
      <path
        fill="#082471"
        d="M177.464 48.1141L176.488 46.9287C175.93 47.2076 175.303 47.3471 174.536 47.3471C172.095 47.3471 170.352 45.6038 170.352 43.233C170.352 40.8621 172.165 39.1189 174.536 39.1189C176.976 39.1189 178.72 40.8621 178.72 43.233C178.72 44.4881 178.231 45.6038 177.395 46.3011L178.929 48.1141H177.464ZM175.582 45.9525L173.838 43.9303H175.303L176.558 45.3946C177.116 44.9065 177.395 44.1395 177.395 43.3027C177.395 41.6292 176.139 40.4438 174.536 40.4438C172.862 40.4438 171.677 41.6989 171.677 43.3027C171.677 44.9065 172.932 46.1617 174.536 46.1617C174.954 46.0919 175.303 46.0222 175.582 45.9525Z"
      />
      <path
        fill="#082471"
        d="M181.3 44.2092V39.1886H182.624V44.0697C182.624 45.2552 183.391 46.0222 184.507 46.0222C185.623 46.0222 186.39 45.2552 186.39 44.0697V39.1886H187.715V44.2092C187.715 46.0222 186.32 47.3471 184.507 47.3471C182.694 47.3471 181.3 46.0919 181.3 44.2092Z"
      />
      <path
        fill="#082471"
        d="M195.246 45.4643H191.689L190.992 47.2076H189.597L192.805 39.2583H194.06L197.338 47.2076H195.943L195.246 45.4643ZM192.178 44.2789H194.827L193.502 40.9319L192.178 44.2789Z"
      />
      <path
        fill="#082471"
        d="M204.311 46.0222V47.2076H199.708V39.2583H201.033V46.0222H204.311Z"
      />
      <path
        fill="#082471"
        d="M206.681 39.2583H208.006V47.2076H206.681V39.2583Z"
      />
      <path
        fill="#082471"
        d="M216.374 40.4438H214.073V47.2076H212.748V40.4438H210.447V39.2583H216.444V40.4438H216.374Z"
      />
      <path
        fill="#082471"
        d="M222.231 44V47.2076H220.907V44L218.048 39.2583H219.442L221.534 42.8146L223.696 39.2583H225.09L222.231 44Z"
      />
      <path
        fill="#082471"
        d="M31.309 62.6181C27.0555 62.6181 22.9414 61.7813 19.1062 60.1775C15.3407 58.5737 11.9937 56.3423 9.13471 53.4834C6.27576 50.6244 3.97464 47.2773 2.44057 43.5119C0.836767 39.6767 0 35.5626 0 31.309C0 27.0555 0.836767 22.9414 2.44057 19.1062C4.04437 15.3407 6.27576 11.9937 9.13471 9.13471C11.9937 6.27576 15.3407 3.97464 19.1062 2.44057C22.9414 0.836767 27.0555 0 31.309 0C35.5626 0 39.6767 0.836767 43.5119 2.44057C47.2773 4.04437 50.6244 6.27576 53.4834 9.13471C56.3423 11.9937 58.6434 15.3407 60.1775 19.1062C61.7813 22.9414 62.6181 27.0555 62.6181 31.309C62.6181 35.5626 61.7813 39.6767 60.1775 43.5119C58.5737 47.2773 56.3423 50.6244 53.4834 53.4834C50.6244 56.3423 47.2773 58.6434 43.5119 60.1775C39.607 61.7813 35.4929 62.6181 31.309 62.6181ZM31.309 2.16165C23.5689 2.16165 16.2472 5.16007 10.7385 10.6688C5.2298 16.1775 2.23139 23.4295 2.23139 31.2393C2.23139 38.9794 5.2298 46.3011 10.7385 51.8098C16.2472 57.3186 23.4992 60.317 31.309 60.317C39.0491 60.317 46.3708 57.3186 51.8796 51.8098C57.3883 46.3011 60.3867 39.0491 60.3867 31.2393C60.3867 23.4992 57.3883 16.1775 51.8796 10.6688C46.3011 5.2298 39.0491 2.16165 31.309 2.16165Z"
      />
      <path
        fill="#082471"
        d="M21.3376 52.019C21.0586 52.0888 20.5705 52.1585 19.8035 52.3677C19.8035 52.5769 19.7338 52.7861 19.7338 53.1347C19.664 53.4834 19.664 53.7623 19.664 53.9018L18.8273 52.5769C18.8273 52.5769 18.3391 52.6466 17.2932 52.8558C17.9905 52.0888 18.2694 51.6704 18.3391 51.6704C18.3391 51.6704 18.0602 51.1823 17.5721 50.2758L18.897 50.8336C18.9667 50.8336 19.0364 50.9034 19.0364 50.9034C19.2456 50.6942 19.5943 50.2758 20.0824 49.7877C20.0127 50.4152 20.0127 50.9033 19.9429 51.3217C19.9429 51.3217 20.0127 51.3217 20.0127 51.3915C20.3613 51.5309 20.7797 51.7401 21.3376 52.019Z"
      />
      <path
        fill="#082471"
        d="M27.4041 53.6228C27.1949 53.7623 26.7068 53.9715 26.0095 54.3202C26.0095 54.5293 26.0792 54.7385 26.0792 55.0872C26.0792 55.4358 26.149 55.7148 26.149 55.8542L25.103 54.7385C25.103 54.7385 24.6149 54.9477 23.6387 55.3661C24.1268 54.4596 24.4057 54.0412 24.4057 53.9715C24.4057 53.9715 24.057 53.5531 23.3597 52.7861L24.7544 53.065C24.8241 53.065 24.8938 53.065 24.8938 53.065C25.0333 52.7861 25.3122 52.3677 25.7306 51.7401C25.8003 52.3677 25.8701 52.8558 25.9398 53.2742C25.9398 53.2742 26.0095 53.2742 26.0095 53.3439C26.3582 53.3439 26.8463 53.4137 27.4041 53.6228Z"
      />
      <path
        fill="#082471"
        d="M33.6799 53.9018C33.4707 54.0412 33.0523 54.3899 32.4247 54.878C32.4944 55.0174 32.5642 55.2964 32.6339 55.645C32.7036 55.9937 32.7734 56.2029 32.8431 56.4121L31.588 55.5055C31.588 55.5055 31.1696 55.7845 30.2631 56.4121C30.542 55.4358 30.7512 54.9477 30.7512 54.9477C30.7512 54.9477 30.3328 54.5991 29.496 53.9715H30.9604C31.0301 53.9715 31.0998 53.9715 31.0998 53.9715C31.2393 53.6926 31.3788 53.2044 31.6577 52.5071C31.8669 53.065 32.0063 53.5531 32.1458 53.9715H32.2155C32.5642 53.832 33.0523 53.832 33.6799 53.9018Z"
      />
      <path
        fill="#082471"
        d="M39.8859 52.8558C39.7464 53.065 39.3978 53.4137 38.8399 54.0412C38.9097 54.1807 39.0491 54.4596 39.1886 54.7385C39.328 55.0175 39.4675 55.2964 39.5372 55.4358L38.0729 54.8083C38.0729 54.8083 37.7242 55.1569 36.9572 55.9239C37.0269 54.9477 37.0967 54.3899 37.0967 54.3899C37.0967 54.3899 36.6086 54.1807 35.7021 53.6926L37.0967 53.3439C37.1664 53.3439 37.2361 53.3439 37.2361 53.3439C37.3059 52.9953 37.3756 52.5072 37.4453 51.8098C37.794 52.3677 38.0032 52.7861 38.2124 53.1347H38.2821C38.7702 53.065 39.2583 52.9953 39.8859 52.8558Z"
      />
      <path
        fill="#082471"
        d="M45.7433 50.6244C45.6038 50.8336 45.3249 51.3217 44.9762 52.019C45.1157 52.1585 45.2552 52.3677 45.4643 52.6466C45.6735 52.9255 45.8827 53.1347 45.9525 53.2742L44.4184 52.9953C44.4184 52.9953 44.1395 53.4137 43.5816 54.3202C43.4422 53.3439 43.4422 52.7861 43.3724 52.7861C43.3724 52.7861 42.8843 52.6466 41.8383 52.3677L43.1632 51.7401C43.233 51.7401 43.3027 51.6704 43.3027 51.6704C43.3027 51.3217 43.233 50.8336 43.1632 50.1363C43.5816 50.5547 43.9303 50.9731 44.2092 51.3217H44.2789C44.6973 51.0428 45.1854 50.8336 45.7433 50.6244Z"
      />
      <path
        fill="#082471"
        d="M18.1299 22.0349L21.8257 24.1268V21.5468L15.8986 18.13V43.233H18.1299V22.0349Z"
      />
      <path
        fill="#082471"
        d="M32.8431 46.7195V40.653V36.8178L45.3249 29.6355V43.233H47.5563V25.7306L45.8827 26.7068L32.8431 34.2377V27.962L26.5674 24.2663V26.8463L30.6117 29.2171V35.5626V46.7195H32.8431Z"
      />
      <path
        fill="#082471"
        d="M37.794 11.4358V29.9144L40.0254 28.5896V7.53091L38.3518 8.50714L23.0808 17.3629V44.4881H20.6403H13.5975H11.9239H9.90176C10.111 44.8368 10.3899 45.2552 10.6688 45.6038C10.9477 46.0222 11.2266 46.3709 11.5056 46.7195H12.7607H25.3122H23.7084H25.3122V18.6878L37.794 11.4358Z"
      />
      <path
        fill="#082471"
        d="M43.9303 44.4881H43.1632H41.7686H40.0254V34.168L37.794 35.4929V40.653V46.7892H43.0935H43.8605H51.7401C52.019 46.3709 52.3677 45.9525 52.6466 45.5341C52.8558 45.1855 53.065 44.9065 53.2742 44.5579H43.9303V44.4881Z"
      />
    </Icon>
  )
}
