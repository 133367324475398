import * as React from "react";
import { GatsbyImage, withArtDirection } from "gatsby-plugin-image";
import {
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
  VStack
} from "@chakra-ui/react";


interface Props {
  node: any
}


export const ConfirmationHero: React.FC<Props> = ( props ) => {
  const images = withArtDirection( props.node.hero_image.desktop.childImageSharp.gatsbyImageData, [
    {
      media: '( max-width: 1024px )',
      image: props.node.hero_image.mobile.childImageSharp.gatsbyImageData
    }
  ]);

  return (
    <VStack
      spacing={{
        base: '30px',
        lg: '40px'
      }}
      w="100%"
    >
      <Grid
        templateColumns={{
          base: '1fr',
          lg: '1fr 1fr'
        }}
        gap={{
          base: '30px',
          lg: '0'
        }}
        w="100%"
        maxH={{
          lg: '406px',
          xl: '483px'
        }}
        h="100%"
      >
        <GridItem>
          <VStack
            justify="center"
            align="flex-start"
            spacing="24px"
            w="100%"
            h="100%"
          >
            <Heading
              as="h1"
              maxW="500px"
              w="100%"
              color="text.primary"
              fontSize="36px"
              fontWeight={ 700 }
            >
              Thank you for your message!
            </Heading>
            <Text
              maxW="500px"
              w="100%"
              color="text.primary"
              fontSize="16px"
              fontWeight={ 400 }
            >
              We'll be in contact with you shortly.
            </Text>
          </VStack>
        </GridItem>
        <GridItem>
          <Flex
            justifyContent="center"
            alignItems="bottom"
            w="100%"
            h="100%"
          >
            <Image
              as={ GatsbyImage }
              image={ images }
              maxW={{
                base: '400px',
                xl: '475px'
              }}
              w={{
                base: '100%',
                sm: '400px',
                xl: '475px'
              }}
              h="auto"
            />
          </Flex>
        </GridItem>
      </Grid>
    </VStack>
  )
}
