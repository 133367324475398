import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
  color?: string | undefined
}


export const ArrowRight: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 11 17" { ...props }>
      <path
        fill="currentColor"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.01597 8.08021L0 1.31091L1.09595 0L10.659 8.08032L1.09595 16.1606L0 14.8497L8.01597 8.08021Z"
      />
    </Icon>
  )
}
