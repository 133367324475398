import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: number | string | undefined
  h?: number | string | undefined
}


export const Ontario: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 316 80" { ...props }>
      <path
        fill="black"
        d="M22.2222 23.5111C8.13333 23.5111 0 31.9111 0 45.1556C0 58.4 8.08889 66.7556 22.1778 66.7556C36.2667 66.7556 44.4 58.3556 44.4 45.1111C44.4 31.8222 36.3111 23.5111 22.2222 23.5111ZM22.2222 58.3111C14.2667 58.3111 9.6 53.1556 9.6 45.0667C9.6 36.9778 14.1778 31.8667 22.1778 31.8667C30.1333 31.8667 34.8 37.0222 34.8 45.1111C34.8 53.2 30.2222 58.3111 22.2222 58.3111Z"
      />
      <path
        fill="black"
        d="M71.0222 65.9999V47.4666C71.0222 43.1555 68.9777 40.9777 65.511 40.9777C62.0444 40.9777 59.8222 43.1999 59.8222 47.4666V65.9999H50.7555V33.9999H59.8222V38.5332C61.9111 35.8666 64.5777 33.3777 69.1999 33.3777C76.0444 33.3777 80.0444 37.911 80.0444 45.2444V65.9555H71.0222V65.9999Z"
      />
      <path
        fill="black"
        d="M97.5111 55.4223C97.5111 57.5112 98.4444 58.5334 100.622 58.5334C102.4 58.5334 103.956 58.1334 105.378 57.3778V64.6667C103.467 65.8223 101.244 66.5334 98.2222 66.5334C92.6667 66.5334 88.8889 64.3112 88.8889 56.9334V41.4223H85.0667V34H88.8889V25.8223H97.5111V34H105.333V41.4223H97.5111V55.4223Z"
      />
      <path
        fill="black"
        d="M124.667 33.6444C119.244 33.6444 115.778 34.6666 112.267 36.2222L114.356 42.7111C117.289 41.6444 120.044 40.8889 123.511 40.8889C128.044 40.8889 130.622 43.0666 130.622 46.8444V47.2889C128.356 46.6666 125.689 46.3111 122.489 46.3111C114.889 46.3111 109.511 49.6444 109.511 56.8444V56.9777C109.511 63.2889 114.444 66.6222 120.489 66.6222C124.933 66.6222 128.089 65.0666 130.4 62.8V66H138.933V47.4222C138.933 38.7555 134.578 33.6444 124.667 33.6444ZM130.533 54.5777C130.533 57.8666 127.6 60.2666 123.333 60.2666C120.4 60.2666 118.311 58.8444 118.311 56.4V56.2666C118.311 53.4222 120.8 51.8666 124.667 51.8666C126.889 51.8666 128.978 52.2666 130.533 52.8889V54.5777Z"
      />
      <path
        fill="black"
        d="M164.622 41.5555C158.578 41.5555 155.067 45.111 155.067 52.7555V65.9999H146.178V33.9999H155.067V40.4444C156.933 36.0444 159.733 33.1555 165.111 33.3777V41.5555H164.622Z"
      />
      <path
        fill="black"
        d="M179.689 22.4V30.4445H170.133V22.4H179.689L179.511 22.5778L179.689 22.4ZM179.289 34.1778L179.467 34H170.4V66H179.467V34L179.289 34.1778Z"
      />
      <path
        fill="black"
        d="M202.978 33.4221C192.133 33.4221 185.644 39.8666 185.644 50.1332C185.644 60.3555 192.089 66.7555 202.933 66.7555C213.822 66.7555 220.311 60.311 220.311 50.0888C220.267 39.7777 213.822 33.4221 202.978 33.4221ZM202.978 58.9777C197.689 58.9777 194.622 55.4666 194.622 50.0443C194.622 44.6221 197.556 41.1555 202.933 41.1555C208.267 41.1555 211.333 44.6666 211.333 50.0888C211.289 55.511 208.356 58.9777 202.978 58.9777Z"
      />
      <path
        fill="black"
        d="M276 0C248.8 0 236 12.8 236 40C236 67.2 248.8 80 276 80C303.2 80 316 67.2 316 40C316 12.8 303.2 0 276 0Z"
      />
      <path
        fill="white"
        d="M276 23.7333C271.111 20.3555 265.733 19.0222 260.844 19.0222C256.711 19.0222 252.756 20 249.244 21.6889C249.911 30.1778 254.756 39.1556 264.4 43.8222C263.911 49.7333 265.422 55.0667 267.867 59.2889C269.956 62.8889 272.756 65.8222 276 68C283.022 63.2 288.4 54.4444 287.6 43.7778C292.978 41.2444 296.844 37.2444 299.289 33.0222C301.378 29.4222 302.489 25.5111 302.8 21.6444C295.067 17.9555 284.844 17.6889 276 23.7333ZM294.4 30.2222C291.822 34.7111 287.778 37.9111 283.244 39.5555C280.933 40.4 278.044 40.4444 276.089 38.3111C275.689 40.8 275.956 43.1111 277.333 44.4889C277.911 45.0667 278.756 45.5111 279.556 45.6444C280.489 45.8222 281.2 45.8667 282.044 45.6889C282.044 51.6889 279.733 56.8444 275.956 60.7111C274.756 59.4667 273.6 58.0444 272.711 56.4444C270.089 51.9555 269.378 46.8889 270.222 42.1333C270.667 39.7333 272.044 37.2 274.844 36.5333C272.889 34.9333 270.756 34 268.889 34.5333C268.089 34.7555 267.289 35.2444 266.756 35.8667C266.133 36.6222 265.778 37.1555 265.467 38C260.267 35.0222 256.933 30.4 255.511 25.2444C257.2 24.8 259.022 24.5333 260.844 24.5333C266.044 24.5333 270.8 26.4444 274.489 29.5111C276.356 31.0667 277.822 33.5555 277.022 36.3111C279.378 35.4222 281.244 34.0444 281.733 32.1333C281.911 31.3333 281.911 30.3555 281.644 29.6444C281.333 28.7111 281.022 28.1333 280.444 27.4222C285.644 24.4 291.289 23.8222 296.489 25.1555C296 26.9333 295.333 28.6222 294.4 30.2222Z"
      />
    </Icon>
  )
}
